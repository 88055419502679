import { ClassTimestampsHelper } from '../ClassHelper';

export const GEO = {
  Country: 'Country',
  Region: 'Region',
  Area: 'Area',
  City: 'City',
};

class Geo extends ClassTimestampsHelper {
  constructor({ type, title } = {}) {
    super(...arguments);
    this.type = type; // GEO
    this.title = title;
  }
}

export class Country extends Geo {
  constructor({ title, vk_id, code } = {}) {
    super({ type: GEO.Country, title });
    this.vk_id = vk_id;
    this.code = code;
  }
}

export class Region extends Geo {
  constructor({ title, country } = {}) {
    super({ type: GEO.Region, title });
    this.country = this.ref(country, Country);
  }
}

export class Area extends Geo {
  constructor({ title, country, region } = {}) {
    super({ type: GEO.Area, title });
    this.country = this.ref(country, Country);
    this.region = this.ref(region, Region);
  }
}

export class City extends Geo {
  constructor({ title, country, region, area } = {}) {
    super({ type: GEO.City, title });
    this.country = this.ref(country, Country);
    this.region = this.ref(region, Region);
    this.area = this.ref(area, Area);
  }
}

export default Geo;