import React from 'react';
import BootstrapAlert from 'react-bootstrap/Alert';
import PropTypes from 'prop-types';
import { ALIGN } from './Table';

export const ALERT_TYPE = {
  INFO: 'primary',
  WARN: 'warning',
  ERROR: 'danger',
  SUCCESS: 'success'
};

export const ALERT_ALIGN = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right'
};

class Alert extends React.Component {
  render() {
    return (
      <BootstrapAlert variant={this.props.type} className={`to-${this.props.align || ALIGN.CENTER}`} onClose={this.props.onClose} dismissible={this.props.dismissible}>
        {this.props.title && (
          <BootstrapAlert.Heading>{this.props.title}</BootstrapAlert.Heading>
        )}
        {this.props.children && (
          <p>{this.props.children}</p>
        )}
      </BootstrapAlert>
    );
  }
};

Alert.propTypes = {
  title: PropTypes.string,
  type: PropTypes.oneOf(Object.values(ALERT_TYPE)),
  onClose: PropTypes.func,
  dismissible: PropTypes.bool,
  align: PropTypes.oneOf(Object.values(ALERT_ALIGN))
};

class Info extends Alert {
  render() {
    return React.createElement(Alert, { ...this.props, type: ALERT_TYPE.INFO });
  }
};

Info.propTypes = { ...Alert.PropTypes };

Alert.Info = Info;

class Warn extends Alert {
  render() {
    return React.createElement(Alert, { ...this.props, type: ALERT_TYPE.WARN });
  }
};

Warn.propTypes = { ...Alert.PropTypes };

Alert.Warn = Warn;

class Error extends Alert {
  render() {
    return React.createElement(Alert, { ...this.props, type: ALERT_TYPE.ERROR });
  }
};

Error.propTypes = { ...Alert.PropTypes };

Alert.Error = Error;

class Success extends Alert {
  render() {
    return React.createElement(Alert, { ...this.props, type: ALERT_TYPE.SUCCESS });
  }
};

Success.propTypes = { ...Alert.PropTypes };

Alert.Success = Success;

export default Alert;