import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RepositoryContext } from '../../store/RepositoryProvider';
import API from '../../server/api';
import NavBar from '../../components/NavBar';
import Badge, { BADGE_TYPE } from '../../components/Badge';
import Table, { ALIGN } from '../../components/Table';
import DateFormat from '../../components/DateFormat';
import Modal from '../../components/Modal';
import Place from './place';
import Button from '../../components/Button';
import BackBar from '../../components/BackBar';
import log from '../../utils/logger';

const PLACE_READY = {
  [true]: BADGE_TYPE.SUCCESS,
  [false]: BADGE_TYPE.WARN
};

const MyRoutes = () => {
  const {t} = useTranslation(['common', 'route']);

  const repository = useContext(RepositoryContext);

  const [schedules, setSchedules] = useState([]);
  const [collapse, setCollapse] = useState(-1);

  const [showPlaceModal, setShowPlaceModal] = useState(false);

  const toogleCollapse = useCallback((key) => {
    setCollapse(collapse === key ? -1 : key);
  }, [collapse]);

  const handleClosePlaceModal = useCallback(() => {
    setShowPlaceModal(false);
  }, []);

  const loadSchedules = async () => {
    repository.list(API.guide.schedules).then((schedules) => {
      let _schedules = schedules.filter((sch) => sch.places.length > 0);
      _schedules.forEach((sch) => {
        sch.passengers = sch.places.reduce((acm, { passengers: { actual, expected } }) => { return { actual: acm.actual + actual, expected: acm.expected + expected } }, { actual: 0, expected: 0 });
      });

      setSchedules(_schedules);
    }).catch((error) => {
      log.error(error);
      setSchedules([]);
    });
  };

  useEffect(() => {
    loadSchedules();
  }, []);

  return (
    <div className="trip-app">
      <NavBar title={(
        <>
          {t('route:my_routes.title')}
          <span className={'title-disabled'} style={{paddingLeft: '20px'}} onClick={() => setShowPlaceModal(true)}>{t('route:my_routes.place')}</span>
        </>
      )} />
      <div className="trip-app-body">
        <Table cols={5}>
          {(schedules.length === 0) && (
            <p>
              {t('common:labels.no_records')}
            </p>
          )}
          {schedules.map((s) => (
            <Fragment key={s.number}>
              <Table.Row onClick={() => toogleCollapse(s.number)}>
                <Table.Cell colspan={2}>
                  {t('route:my_routes.route', { route: s.number })}
                </Table.Cell>
                <Table.Cell colspan={3} align={ALIGN.RIGHT}>
                  <DateFormat.DateTime date={s.date} />
                </Table.Cell>
              </Table.Row>
              <Table.Row onClick={() => toogleCollapse(s.number)}>
                <Table.Cell colspan={5} className="small">
                  {s.excursions[0].title}
                </Table.Cell>
              </Table.Row>
              <Table.Row onClick={() => toogleCollapse(s.number)}>
                <Table.Cell colspan={3} className="small">
                  {s.transports.map((transport) => (
                    <div key={transport.id}>{transport.title}</div>
                  ))}
                </Table.Cell>
                <Table.Cell colspan={2} className="small" align={ALIGN.RIGHT}>
                  <Badge type={PLACE_READY[s.passengers.actual === s.passengers.expected]}>
                    {t('route:my_routes.seat', s.passengers)}
                  </Badge>
                </Table.Cell>
              </Table.Row>
              {s.places.map((place) => (
                <Table.Row className="sub-row" style={collapse === s.number ? {} : { display: 'none' }}>
                  <Table.Cell className="small"><DateFormat.Time date={place.time} /></Table.Cell>
                  <Table.Cell className="small" colspan={3}>{place.title}</Table.Cell>
                  <Table.Cell className="small" align={ALIGN.RIGHT}>
                    <Badge type={PLACE_READY[place.passengers.actual === place.passengers.expected]}>
                      {t('route:my_routes.count_place', place.passengers)}
                    </Badge>
                  </Table.Cell>
                </Table.Row>
              ))}
              <Table.Row delim={true} />
            </Fragment>
          ))}
        </Table>

        <Modal show={showPlaceModal} onHide={handleClosePlaceModal}>
          <Modal.Header closeButton>
            <BackBar title={t('route:place.title')}/>
          </Modal.Header>
          <Modal.Body>
            <Place place={{}} onNextStop={() => {}}/>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClosePlaceModal}>{t('common:button.close')}</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

export default MyRoutes;