import ClassHelper from '../ClassHelper';

class PassengerActualPrice extends ClassHelper {
  constructor({ amount, partialValue, leftToPay, discountValue } = {}) {
    super(...arguments);
    this.amount = amount;
    this.partialValue = partialValue;
    this.leftToPay = leftToPay;
    this.discountValue = discountValue;
  }
}

export default PassengerActualPrice;