import { useEffect, useState, useContext, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Alert from '../../components/Alert';
import Field from '../../components/Field';
import Price, { CURRENCY } from '../../components/Price';
import Select from '../../components/Select';
import Checkbox from '../../components/Checkbox';
import { PaymentMethod } from '../../models/types';
import Input, { ACCEPT_FILE_FORMAT } from '../../components/Input';
import { BUTTON_TYPE } from '../../components/Button';
import RefundPoliceRule from '../../models/refund/RefundPoliceRule';
import { RefundContext } from '../../store/RefundProvider';
import { v4 as uuidv4 } from 'uuid';
import Image from '../../components/Image';

const StepRefundInfo = () => {
  const {t} = useTranslation(['common', 'refund']);

  const refundInfo = useContext(RefundContext);

  const [paymentMethods] = useState(Object.values(PaymentMethod));
  
  const [stepRefundMethod, setStepRefundMethod] = refundInfo.useState('refundMethod');
  const [stepUseRule, setStepUseRule] = refundInfo.useState('useRule');
  const [stepRefundAmount, setStepRefundAmount] = refundInfo.useState('refundAmount');
  const [stepRule, setStepRule] = refundInfo.useState('rule');
  const [stepOrderPaymentMethod] = refundInfo.useState('orderPaymentMethod');
  const [stepOrderAmount] = refundInfo.useState('orderAmount');
  const [stepReason, setStepReason] = refundInfo.useState('reason');
  const [stepFiles, setStepFiles] = refundInfo.useState('files');

  const [maximumRefund, setMaximumRefund] = useState(0);
  const [maximumRefundError, setMaximumRefundError] = useState(null);
  const [filePreviews, setFilePreviews] = useState([]);
  
  const [errors, setErrors] = useState([]);

  const handleCloseError = (uid) => {
    setErrors([]);
  };

  const handleUseRule = (val) => {
    setStepUseRule(val);
  };

  const handleRefundAmount = (val) => {
    setStepRefundAmount(val);
  };

  const handleFile = (file) => {
    if (file) {
      const id = uuidv4().replace(/-/g, '');
      stepFiles.push({ id, file });
      setStepFiles([ ...stepFiles ]);

      if (file.type !== ACCEPT_FILE_FORMAT.PDF) {
        const reader = new FileReader();
        reader.onload = () => {
          filePreviews.push({ id, src: reader.result });
          setFilePreviews([ ...filePreviews ]);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const validate = () => {
    let amount = Number(stepRefundAmount);
    if (amount && amount > maximumRefund) {
      setMaximumRefundError(t('refund:step_refund_info.maximumError'));
    } else {
      setMaximumRefundError('')
    }
  };

  useEffect(() => {
    setStepRefundMethod(stepOrderPaymentMethod);
    setStepRule(new RefundPoliceRule({ from: 2, to: 4, value: '11%' }));
  }, []);

  useEffect(() => {
    setMaximumRefund((stepRule && stepUseRule) ? stepRule.applyTo(stepOrderAmount) : stepOrderAmount);
  }, [stepUseRule, stepRule, stepOrderAmount]);

  useEffect(() => {
    validate();
  }, [stepUseRule, stepRule, stepOrderAmount, stepRefundAmount, maximumRefund]);


  return (
    <>
      {errors.map((error, i) => (
        <Alert type={error.type} key={error.uid} title={error.title} dismissible={true} onClose={() => handleCloseError(error.uid)}>{error.message}</Alert>
      ))}

      <Field title={t('refund:step_refund_info.refund_method')}>
        <Select
          value={stepRefundMethod}
          options={paymentMethods.map((method) => ({ value: method, text: t(`common:payment_method.${method}`) }))}
          onSelect={setStepRefundMethod}
        />
      </Field>

      <Field>
        <Checkbox.Slide title={t('refund:step_refund_info.use_rule')} value={1} checked={stepUseRule} onChange={(value, isChecked) => handleUseRule(isChecked)}/>
      </Field>

      <Field title={t('refund:step_refund_info.refund_amount')}>
        <Price.Input
          value={stepRefundAmount}
          onChange={handleRefundAmount}
          note={`${t('refund:step_refund_info.maximum')} ${Price.Text({currency: CURRENCY.RUB, value: maximumRefund})}`}
          max={maximumRefund}
          error={maximumRefundError}
        />
      </Field>

      {stepUseRule && (
        <Field title={t('refund:step_refund_info.rule', stepRule)}>
          {stepRule && t('refund:step_refund_info.rule_description', {rule: stepRule.getValue(), value: Price.Text({value: stepRule.applyTo(stepOrderAmount)}), total: Price.Text({value: stepOrderAmount}) })}
        </Field>
      )}

      <Field title={t('refund:step_refund_info.reason')}>
        <Input value={stepReason} onChange={setStepReason} />
      </Field>

      <Field title={t('refund:step_refund_info.documents')}>
        <Input.File type={BUTTON_TYPE.PRIMARY} onChange={handleFile} label={t('refund:button.select_photo')} accept={[ACCEPT_FILE_FORMAT.IMAGE, ACCEPT_FILE_FORMAT.PDF]} />
        {filePreviews && filePreviews.map((file) => (
          <Fragment key={file.id}>
            <Image src={file.src} width={60} height={100} />
          </Fragment>
        ))}
        {/* <Button.Panel>
          <Button position={BUTTON_POSITION.LEFT} variant={BUTTON_TYPE.PRIMARY}>{t('refund:button.create_photo')}</Button>
          <Button position={BUTTON_POSITION.RIGHT} variant={BUTTON_TYPE.PRIMARY}>{t('refund:button.select_photo')}</Button>
        </Button.Panel> */}
      </Field>
    </>
  )
}

StepRefundInfo.propTypes = {
  onChange: PropTypes.func,
  onComplete: PropTypes.func,
  onError: PropTypes.func,
};

export default StepRefundInfo;