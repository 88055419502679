import ClassHelper from '../ClassHelper';
import BusFloor from './BusFloor';
import { TransportType } from '../types';

class TransportConfig extends ClassHelper {
  constructor({ type, kindOfTransport, isCustom, floors = [] } = {}) {
    super(...arguments);
    this.type = type; // TransportType
    this.kindOfTransport = kindOfTransport; // KindOfTransport
    if (type === TransportType.BUS) {
      this.isCustom = isCustom;
      this.floors = this.array(floors, BusFloor);
    }
  }
}

export default TransportConfig;