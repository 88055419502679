import ClassHelper from '../ClassHelper';

const RuleType = {
  PERCENT: '%',
  VALUE: ''
};

class RefundPoliceRule extends ClassHelper {
  constructor({ from, to, value } = {}) {
    super(...arguments);
    this.from = from;
    this.to = to;
    this.type = /^[\d]+%$/.test(value) ? RuleType.PERCENT : RuleType.VALUE;
    this.value = this.type === RuleType.PERCENT ? Number(value.replace('%', '')) : Number(value);
  }

  applyTo(amount) {
    let refundAmount = 0;

    if (typeof amount === 'string' && !isNaN(Number(amount))) {
      refundAmount = Number(amount);
    } else if(typeof amount === 'number') {
      refundAmount = amount;
    }

    if (this.type === RuleType.PERCENT) {
      refundAmount = refundAmount * this.value / 100;
    }

    return refundAmount;
  }

  getValue() {
    return this.type === RuleType.PERCENT ? `${this.value}%` : this.value;
  }
}

export default RefundPoliceRule;