import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { BUTTON_POSITION, BUTTON_TYPE, TOGGLE_TYPE } from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import Modal from '../../components/Modal';
import Alert, { ALERT_TYPE } from '../../components/Alert';
import BackBar from '../../components/BackBar';
import Badge, { BADGE_TYPE } from '../../components/Badge';
import DateFormat, { DATE_FORMAT } from '../../components/DateFormat';
import DatePeriod from '../../components/DatePeriod';
import ErrorHint from '../../components/ErrorHint';
import Input from '../../components/Input';
import Field, { LINE_MODE } from '../../components/Field';
import Image, { getRandomImageSrc } from '../../components/Image';
import { IMAGE_SIZES } from '../../models/file/Image';
import ProfileIcon from '../../components/icons/Profile';
import ImageGallery from '../../components/ImageGallery';
import Info, { INFO_VIEW_MODE } from '../../components/Info';
import InfoDescription from '../../components/InfoDescription';
import InputAutocomplete from '../../components/InputAutocomplete';
import InputWithError from '../../components/InputWithError';
import List from '../../components/List';
import OneTimeCode from '../../components/OneTimeCode';
import PersonName, { LOCALE_FORMAT, PERSON_NAME_FORMAT } from '../../components/PersonName';
import Phone from '../../components/Phone';
import Price, { CURRENCY } from '../../components/Price';
import Section from '../../components/Section';
import Select from '../../components/Select';
import SelectAutocomplete from '../../components/SelectAutocomplete';
import SelectDate, { DAY_PICKER_MODE } from '../../components/SelectDate';
import Stepper, { Step } from '../../components/Stepper';
import Stepper2, { Step as Step2 } from '../../components/Stepper2';
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup';
import Table from '../../components/Table';

const LANGUAGES = [
  'ru', 'en'
];

export const STEPS = {
  step1: 0,
  step2: 1,
  step3: 2
};

export const STEPS2 = {
  step21: 0,
  step22: 1,
  step23: 2
};

const UIExample = () => {
  const {t, i18n} = useTranslation(['common']);

  const [showReviewModal, setShowReviewModal] = useState(false);
  const [inputs, setInputs] = useState([]);
  const [inputsWithError, setInputsWithError] = useState([]);
  const [autocompleteChangeValue, setAutocompleteChangeValue] = useState('');
  const [autocompleteSelectValue, setAutocompleteSelectValue] = useState('');
  const [autocompleteInputValue, setAutocompleteInputValue] = useState('');
  const [autocompleteList] = useState(['apple', 'banana', 'cherry', 'orange', 'ananas', 'lemon', 'blueberry']);
  const [languageIdx, setLanguageIdx] = useState(0);
  const [smsCode, setSmsCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('+79010011122');
  const [sectionA, setSectionA] = useState(true);
  const [sectionB, setSectionB] = useState(false);
  const [autocompleteValue, setAutocompleteValue] = useState('');
  const [testDate, setTestDate] = useState('');
  const [page, setPage] = useState(0);
  const [page2, setPage2] = useState(0);
  const [steps, setSteps] = useState(Object.keys(STEPS).map((step) => new Step({ title: `Title ${step}`, id: step })));
  const [steps2, setSteps2] = useState(Object.keys(STEPS2).map((step) => new Step2({ title: `Title ${step}`, id: step })));

  const handleCloseReviewModal = useCallback(() => {
    setShowReviewModal(false);
  }, []);

  const changeLanguageHandler = () => {
    let idx = languageIdx + 1;
    if (idx === LANGUAGES.length) {
      idx = 0;
    }
    setLanguageIdx(idx);
    i18n.changeLanguage(LANGUAGES[idx]);
  };

  const handleSelectStep = useCallback((idx) => {
    setPage(idx);
  }, []);

  const nextPage = () => {
    let newPage = page + 1;
    if (newPage < steps.length && newPage >= 0) {
      setPage(newPage);
    }
  };

  const prevPage = () => {
    let newPage = page - 1;
    if (newPage < steps.length && newPage >= 0) {
      setPage(newPage);
    }
  };

  const handleSelectStep2 = useCallback((idx) => {
    setPage2(idx);
  }, []);

  const nextPage2 = () => {
    let newPage = page2 + 1;
    if (newPage <= steps2.length && newPage >= 0) {
      steps2[page2].complete();
      if (newPage < steps2.length) {
        steps2[newPage].select();
        setPage2(newPage);
      }
    }
  };

  const prevPage2 = () => {
    let newPage = page2 - 1;
    if (newPage < steps2.length && newPage >= 0) {
      setPage2(newPage);
      steps2[newPage].select();
    }
  };

  useEffect(() => {
    changeLanguageHandler(LANGUAGES[languageIdx]);
  }, []);

  return (
    <div className="trip-app">
      <div className={`trip-app-header`}>
        {t('common:navbar.ui_demo')}
      </div>
      <div className="trip-app-body">
        <div className="list-row"><div><b>Component: Alert</b></div>
          <Alert type={ALERT_TYPE.INFO} title="For your attention" dismissible={false} />
          <Alert type={ALERT_TYPE.WARN} title="DANGER!!!" dismissible={true} />
          <Alert type={ALERT_TYPE.ERROR} dismissible={true}>This is a Alert.Error</Alert>
          <Alert type={ALERT_TYPE.INFO} title="Information" dismissible={false}>This is a Alert.Info</Alert>
          <Alert type={ALERT_TYPE.WARN}>This is a Alert.Warning</Alert>
          <Alert type={ALERT_TYPE.SUCCESS} title="Congratulate!" dismissible={true}>This is a Alert.Success</Alert>
        </div>
        <div className="list-row"><div><b>Component: BackBar</b></div>
          <BackBar title={t('order:new_order.title')}>
            <p>INNER CONTENT</p>
          </BackBar>
        </div>
        <div className="list-row"><div><b>Component: Badge</b></div>
          <Badge type={BADGE_TYPE.SUCCESS}>{t(`common:payment_status.${BADGE_TYPE.SUCCESS}`)}</Badge>
        </div>
        <div className="list-row"><div><b>Component: Button</b></div>
          <Button variant={BUTTON_TYPE.PRIMARY} onClick={() => {alert('Button Primary')}}>Button Primary</Button>
          <Button variant={BUTTON_TYPE.SECONDARY} onClick={() => {alert('Button Secondary')}}>Button Secondary</Button>
          <Button variant={BUTTON_TYPE.SELECTED} onClick={() => {alert('Button Selected')}}>Button Selected</Button>
        </div>
        <div className="list-row"><div><b>Component: Button.Group</b></div>
          <Button.Group>
            <Button variant={BUTTON_TYPE.PRIMARY} onClick={() => {alert('Button Left')}}>Left</Button>
            <Button variant={BUTTON_TYPE.SECONDARY} onClick={() => {alert('Button Middle')}}>Middle</Button>
            <Button variant={BUTTON_TYPE.PRIMARY} onClick={() => {alert('Button Right')}}>Right</Button>
          </Button.Group>
        </div>
        <div className="list-row"><div><b>Component: Button.ToggleGroup</b></div>
          <Button.ToggleGroup type={TOGGLE_TYPE.CHECKBOX} value={[1, 2]}>
            <Button.Toggle id="chk-1" type={TOGGLE_TYPE.CHECKBOX} variant={BUTTON_TYPE.PRIMARY} value={1}>Chk1</Button.Toggle>
            <Button.Toggle id="chk-2" type={TOGGLE_TYPE.CHECKBOX} variant={BUTTON_TYPE.PRIMARY} value={2}>Chk2</Button.Toggle>
            <Button.Toggle id="chk-3" type={TOGGLE_TYPE.CHECKBOX} variant={BUTTON_TYPE.PRIMARY} value={3}>Chk3</Button.Toggle>
          </Button.ToggleGroup>
          <Button.ToggleGroup type={TOGGLE_TYPE.RADIO} name={'btn-rad-2'} value={3}>
            <Button.Toggle id="rad-1" variant={BUTTON_TYPE.SECONDARY} value={1}>Rad1</Button.Toggle>
            <Button.Toggle id="rad-2" variant={BUTTON_TYPE.SECONDARY} value={2}>Rad2</Button.Toggle>
            <Button.Toggle id="rad-3" variant={BUTTON_TYPE.SECONDARY} value={3}>Rad3</Button.Toggle>
          </Button.ToggleGroup>
        </div>
        <div className="list-row"><div><b>Component: Button.Panel</b></div>
          <Button.Panel className="mb-2">
            <Button variant={BUTTON_TYPE.PRIMARY} position={BUTTON_POSITION.FLOAT} onClick={() => {alert('Button Left')}}>Left</Button>
            <Button variant={BUTTON_TYPE.SECONDARY} position={BUTTON_POSITION.FLOAT} onClick={() => {alert('Button Middle')}}>Middle</Button>
            <Button variant={BUTTON_TYPE.PRIMARY} position={BUTTON_POSITION.FLOAT} onClick={() => {alert('Button Right')}}>Right</Button>
          </Button.Panel>
          <Button.Panel className="mb-2">
            <Button variant={BUTTON_TYPE.PRIMARY} position={BUTTON_POSITION.FLOAT} onClick={() => {alert('Button Left')}}>Left</Button>
            <Button variant={BUTTON_TYPE.PRIMARY} position={BUTTON_POSITION.FLOAT} onClick={() => {alert('Button Middle 1')}}>Middle</Button>
            <Button variant={BUTTON_TYPE.PRIMARY} position={BUTTON_POSITION.FLOAT} onClick={() => {alert('Button Middle 2')}}>Middle</Button>
            <Button variant={BUTTON_TYPE.PRIMARY} position={BUTTON_POSITION.FLOAT} onClick={() => {alert('Button Right')}}>Right</Button>
          </Button.Panel>
          <Button.Panel className="mb-2">
            <Button variant={BUTTON_TYPE.SECONDARY} position={BUTTON_POSITION.LEFT} onClick={() => {alert('Button Left')}}>Left</Button>
            <Button variant={BUTTON_TYPE.SECONDARY} position={BUTTON_POSITION.RIGHT} onClick={() => {alert('Button Right')}}>Right</Button>
          </Button.Panel>
          <Button.Panel>
            <Button variant={BUTTON_TYPE.PRIMARY} position={BUTTON_POSITION.RIGHT} onClick={() => {alert('Button Right')}}>Right</Button>
          </Button.Panel>
        </div>
        <div className="list-row"><div><b>Component: Checkbox</b></div>
          <Checkbox title="Checkbox 1" value={1} checked={true} onChange={(value, isChecked) => alert(`${value} is ${isChecked ? '' : 'not '}checked`)}/>
          <Checkbox title="Checkbox 2" value={2} checked={false} onChange={(value, isChecked) => alert(`${value} is ${isChecked ? '' : 'not '}checked`)}/>
        </div>
        <div className="list-row"><div><b>Component: Checkbox.Group - list</b></div>
          <Checkbox.Group list={[
            { title: 'box 1', value: 1, checked: false},
            { title: 'box 2', value: 2, checked: true},
            { title: 'box 3', value: 3, checked: true},
            { title: 'box 4', value: 4, checked: false}
          ]} onChange={(values) => alert(values)}/>
        </div>
        <div className="list-row"><div><b>Component: Checkbox.Group - children</b></div>
          <Checkbox.Group>
            {[
              { title: 'box 1', value: 1, checked: false},
              { title: 'box 2', value: 2, checked: true},
              { title: 'box 3', value: 3, checked: true},
              { title: 'box 4', value: 4, checked: false}
            ].map((children, i) => (
              <Checkbox key={i} title={children.title} value={children.value} checked={children.checked} onChange={(value, isChecked) => alert(`${value} is ${isChecked ? '' : 'not '}checked`)}/>
            ))}
          </Checkbox.Group>
        </div>
        <div className="list-row"><div><b>Component: Checkbox.Button</b></div>
          <Checkbox.Button title="Checkbox 1" value={1} checked={true} onChange={(value, isChecked) => alert(`${value} is ${isChecked ? '' : 'not '}checked`)}/>
          <Checkbox.Button title="Checkbox 2" value={2} checked={false} onChange={(value, isChecked) => alert(`${value} is ${isChecked ? '' : 'not '}checked`)}/>
        </div>
        <div className="list-row"><div><b>Component: Checkbox.Slide</b></div>
          <Checkbox.Slide title="Checkbox 1" value={1} checked={true} onChange={(value, isChecked) => alert(`${value} is ${isChecked ? '' : 'not '}checked`)}/>
          <Checkbox.Slide title="Checkbox 2" value={2} checked={false} onChange={(value, isChecked) => alert(`${value} is ${isChecked ? '' : 'not '}checked`)}/>
        </div>
        <div className="list-row"><div><b>Component: DateFormat</b></div>
          <table>
            <tbody>
              <tr><td>Default</td><td><DateFormat date={new Date()} /></td></tr>
              <tr><td>[DATE_FORMAT.REQUEST]</td><td><DateFormat date={new Date()} format={DATE_FORMAT.REQUEST} /></td></tr>
              <tr><td>.Request</td><td><DateFormat.Request date={new Date()} /></td></tr>
              <tr><td>[DATE_FORMAT.DAY]</td><td><DateFormat date={new Date(2022, 5, 18, 7, 53, 14)} format={DATE_FORMAT.DAY} /></td></tr>
              <tr><td>.Day</td><td><DateFormat.Day date={new Date(2022, 5, 18, 7, 53, 14)} /></td></tr>
              <tr><td>[DATE_FORMAT.TIME]</td><td><DateFormat date={new Date(2022, 5, 18, 7, 53, 14)} format={DATE_FORMAT.TIME} /></td></tr>
              <tr><td>.Time</td><td><DateFormat.Time date={new Date(2022, 5, 18, 7, 53, 14)} /></td></tr>
              <tr><td>[DATE_FORMAT.FULL]</td><td><DateFormat date={new Date(2022, 5, 18, 7, 53, 14)} format={DATE_FORMAT.FULL} /></td></tr>
              <tr><td>.DateTime</td><td><DateFormat.DateTime date={new Date(2022, 5, 18, 7, 53, 14)} /></td></tr>
            </tbody>
          </table>
        </div>
        <div className="list-row"><div><b>Component: DatePeriod</b></div>
          <div><DatePeriod start={'07-24-2010'} end={'03-11-2011'} onClick={(period) => alert(period)} /></div>
          <div><DatePeriod start={'07-24-2010'} onClick={(period) => alert(period)} /></div>
          <div><DatePeriod start={'07-24-2010'} end={'03-31-2011'} onClick={(period) => alert(period)} /></div>
          <div><DatePeriod start={'07-01-2010'} end={'03-11-2011'} onClick={(period) => alert(period)} /></div>
          <div><DatePeriod start={'07-01-2010'} end={'03-31-2011'} onClick={(period) => alert(period)} /></div>
          <div><DatePeriod start={'07-01-2010'} end={'07-31-2010'} onClick={(period) => alert(period)} /></div>
          <div><DatePeriod start={'07-01-2010'} end={'07-20-2010'} onClick={(period) => alert(period)} /></div>
        </div>
        <div className="list-row"><div><b>Component: ErrorHint</b></div>
          <Input />
          <ErrorHint error={'ERROR: input text !!!'} />
        </div>
        <div className="list-row"><div><b>Component: Field</b></div>
          <Field title="Example With Select">
            <Select
              value={-1}
              empty={{ value: -1, text: 'Empty Value'}}
              options={[
                { value: 1, text: 'Option 1' },
                { value: 2, text: 'Option 2' },
                { value: 3, text: 'Option 3' },
                { value: 4, text: 'Option 4' },
                { value: 5, text: 'Option 5' }
              ]}
              onSelect={console.log}
            />
          </Field>
          <Field title="Example With Text">Information about ...</Field>
          <Field title="Example One Line" mode={LINE_MODE.ONE}>Short info</Field>
          <Field title="Example One Line" mode={LINE_MODE.ONE}>Short info</Field>
        </div>
        <div className="list-row"><div><b>Component: Image</b></div>
          <Image src={getRandomImageSrc()} width={IMAGE_SIZES.avatar.sizes.w256.width} height={IMAGE_SIZES.avatar.sizes.w256.height} notFound={<p>NOT FOUND</p>} onLoad={() => console.log('Loaded 1')} onError={() => console.log('Error 1')} />
          <Image src={undefined} width={IMAGE_SIZES.avatar.sizes.w256.width} height={IMAGE_SIZES.avatar.sizes.w256.height} notFound={<p>SRC not defined</p>} onLoad={() => console.log('Loaded 2')} onError={() => console.log('Error 2')} />
          <Image src={'localhost:8888/image.not.found.png'} width={IMAGE_SIZES.avatar.sizes.w256.width} height={IMAGE_SIZES.avatar.sizes.w256.height} notFound={<p>Invalid url</p>} onLoad={() => console.log('Loaded 3')} onError={() => console.log('Error 3')} />
        </div>
        <div className="list-row"><div><b>Component: Image as Profile Photo</b></div>
          <Image className="profile-card-image" src={getRandomImageSrc()} width={IMAGE_SIZES.avatar.sizes.w256.width} height={IMAGE_SIZES.avatar.sizes.w256.height} notFound={<p>NOT FOUND</p>} />
          <Image className="profile-card-image" src={undefined} width={IMAGE_SIZES.avatar.sizes.w256.width} height={IMAGE_SIZES.avatar.sizes.w256.height} notFound={<ProfileIcon className="profile-card-image" />} />
        </div>
        <div className="list-row"><div><b>Component: ImageGallery</b></div>
          <ImageGallery list={[
            { title: 'Image 1', path: getRandomImageSrc(), description: 'image description 1' },
            { title: 'Image 2', path: getRandomImageSrc(), description: 'image description 2' },
            { title: 'Image 3', path: getRandomImageSrc(), description: 'image description 3' },
            { title: 'Image 4', path: getRandomImageSrc(), description: 'image description 4' },
            { title: 'Image 5', path: getRandomImageSrc(), description: 'image description 5' }
          ]} />
        </div>
        <div className="list-row"><div><b>Component: Info</b></div>
          <Info mode={INFO_VIEW_MODE.ONE} title="Info One Line">Info text 1</Info>
          <Info mode={INFO_VIEW_MODE.TWO} title="Info Two Line">Info text 2</Info>
          <Info>Info without Title</Info>
        </div>
        <div className="list-row"><div><b>Component: InfoDescription</b></div>
          <InfoDescription description="Info with delimiter" delim={true}>InfoDescription text 1</InfoDescription>
          <InfoDescription description="Info w/o delimiter" delim={false}>InfoDescription text 2</InfoDescription>
          <InfoDescription delim>InfoDescription with delimiter</InfoDescription>
        </div>
        <div className="list-row"><div><b>Component: Input</b></div>
          {[
            { value: 'test value. read only = true', readOnly: true, type: "input" },
            { value: 'test value. read only = false', readOnly: false, type: "input" },
            { value: 'ABCD1234$%^&', readOnly: false, type: "password" },
          ].map((input, i) => (
            <Input key={i} value={inputs[i] || input.value} type={input.type} readOnly={input.readOnly} onChange={(value) => { inputs[i] = value; setInputs([...inputs]); }} onEnter={alert} />
          ))}
        </div>
        <div className="list-row"><div><b>Component: InputAutocomplete</b></div>
          <p>Select: {autocompleteSelectValue}</p>
          <p>Change: {autocompleteChangeValue}</p>
          <p>Input: {autocompleteInputValue}</p>
          <InputAutocomplete
            value={autocompleteInputValue}
            list={autocompleteList}
            placeholder={'Input fruit'}
            onChange={setAutocompleteChangeValue}
            onSelect={setAutocompleteSelectValue}
            onInput={setAutocompleteInputValue}
          />
        </div>
        <div className="list-row"><div><b>Component: InputWithError</b></div>
          {[
            { value: 'test value', readOnly: false, type: "input", validate: (value) => !value ? 'Value is not set' : '' },
            { value: '', readOnly: false, type: "input", validate: (value) => /^\d*$/.test(value) ? '' : 'Value should have only digists' },
            { value: 'ABCD1234$%^&', readOnly: false, type: "password", validate: (value) => /^[\d$%&*\-+a-zA-Z]*$/.test(value) ? '' : 'Password should have only digists, letters and special symbols - $,%,&,*,-,+' },
          ].map((input, i) => (
            <InputWithError key={i}
              value={inputsWithError[i] ? inputsWithError[i].value : input.value}
              error={inputsWithError[i] ? inputsWithError[i].error : input.validate(input.value)}
              type={input.type}
              readOnly={input.readOnly}
              onChange={(value) => {
                inputsWithError[i] = {
                  value: value,
                  error: input.validate(value),
                };
                setInputsWithError([...inputsWithError]);
              }}
              onEnter={alert}
            />
          ))}
        </div>
        <div className="list-row"><div><b>Component: List</b></div>
          <List>
            {[
              ['AAAA', 'aaaa'],
              ['BBBB', 'bbbb'],
              ['CCCC', 'cccc'],
              ['DDDD', 'dddd'],
              ['EEEE', 'eeee']
            ].map((s, i) => (
              <List.Row key={i}>
                <List.Cell className="align-right">{s[0]}</List.Cell>
                <List.Cell className="align-left">{s[1]}</List.Cell>
              </List.Row>
            ))}
          </List>
        </div>
        <div className="list-row"><div><b>Component: Modal</b></div>
          <Button onClick={() => setShowReviewModal(true)}>Open Modal</Button>
          <Modal show={showReviewModal} onHide={handleCloseReviewModal}>
            <Modal.Header closeButton>
              Modal Title
            </Modal.Header>
            <Modal.Body>
              Modal Body
            </Modal.Body>
            <Modal.Footer>
              <Button.Panel>
                <Button position={BUTTON_POSITION.RIGHT} variant={BUTTON_TYPE.SECONDARY} onClick={handleCloseReviewModal}>OK</Button>
              </Button.Panel>
            </Modal.Footer>
          </Modal>
        </div>
        <div className="list-row"><div><b>Component: Language</b></div>
            <Button onClick={changeLanguageHandler}>Switch Language</Button>
            <div>Current Language: {LANGUAGES[languageIdx]}</div>
            <div>Test: {t('common:_example._variable', {framework: 'I18Next'})}</div>
        </div>
        <div className="list-row"><div><b>Component: OneTimeCode</b></div>
          <OneTimeCode className="text" value={smsCode} name="sms_code" length={6} onChange={setSmsCode} />
          <div>Result: {smsCode}</div>
        </div>
        <div className="list-row"><div><b>Component: PersonName</b></div>
          <div><i style={{marginRight: '20px'}}>Full RU:</i><PersonName first_name={'John'} last_name={'malcovich'} middle_name={'AARON'} format={PERSON_NAME_FORMAT.FULL} locale={LOCALE_FORMAT.RU} /></div>
          <div><i style={{marginRight: '20px'}}>Short RU:</i><PersonName first_name={'John'} last_name={'malcovich'} middle_name={'AARON'} format={PERSON_NAME_FORMAT.SHORT} locale={LOCALE_FORMAT.RU} /></div>
          <div><i style={{marginRight: '20px'}}>Full EN:</i><PersonName first_name={'John'} last_name={'malcovich'} middle_name={'AARON'} format={PERSON_NAME_FORMAT.FULL} locale={LOCALE_FORMAT.EN} /></div>
          <div><i style={{marginRight: '20px'}}>Short EN:</i><PersonName first_name={'John'} last_name={'malcovich'} middle_name={'AARON'} format={PERSON_NAME_FORMAT.SHORT} locale={LOCALE_FORMAT.EN} /></div>
        </div>
        <div className="list-row"><div><b>Component: Phone</b></div>
          <Phone value={phoneNumber}/>
          <Phone.Input className="form-control" value={phoneNumber} onInput={setPhoneNumber}/>
        </div>
        <div className="list-row"><div><b>Component: Price</b></div>
          <div><Price value={350870} /></div>
          <div><Price currency={CURRENCY.EUR} value={13354} /></div>
          <div><Price currency={CURRENCY.USD} value={44664} /></div>
          <div><Price currency={CURRENCY.RUB} value={7654165} /></div>
          <div><Price.Input value={350870} /></div>
          <div><Price.Input currency={CURRENCY.EUR} value={87504} /></div>
          <div><Price.Input currency={CURRENCY.USD} value={8642} /></div>
        </div>
        <div className="list-row"><div><b>Component: QRCode</b></div>
        </div>
        <div className="list-row"><div><b>Component: Section</b></div>
          <Section title={`SECTION A ${sectionA ? 'visible' : 'hidden'}`} visible={sectionA} onVisible={setSectionA}>
            AAAAAAAAAAAAA
          </Section>
          <Section title={`SECTION B ${sectionB ? 'visible' : 'hidden'}`} visible={sectionB} onVisible={setSectionB}>
            BBBBBBBBBBBBB
          </Section>
        </div>
        <div className="list-row"><div><b>Component: Select</b></div>
          <Select
            value={-1}
            empty={{ value: -1, text: 'Empty Value'}}
            options={[
              { value: 1, text: 'Option 1' },
              { value: 2, text: 'Option 2' },
              { value: 3, text: 'Option 3' },
              { value: 4, text: 'Option 4' },
              { value: 5, text: 'Option 5' }
            ]}
            onSelect={console.log}
          />
        </div>
        <div className="list-row"><div><b>Component: SelectAutocomplete</b></div>
          <SelectAutocomplete
            apiHandler={(query) => {
              let rows = ['apple', 'banana', 'cherry', 'orange', 'ananas', 'lemon', 'blueberry'].filter((value) => query === '' || (value.indexOf(query) >= 0) );
              return rows;
            }} template={(row) => row}
            value={autocompleteValue}
            onChange={setAutocompleteValue}
            getValue={(value) => value}
          />
          {autocompleteValue}
        </div>
        <div className="list-row"><div><b>Component: SelectDate</b></div>
          <SelectDate
            mode={DAY_PICKER_MODE.SINGLE}
            selected={testDate}
            onChange={(val) => {
              setTestDate(DateFormat.Request({ date: val }));
            }}
            readOnly={false}
            fromYear={1900}
            toYear={new Date().getFullYear()}
          />
        </div>
        <div className="list-row"><div><b>Component: Stepper</b></div>
          <Stepper steps={steps} activeStep={page} onSelect={handleSelectStep}></Stepper>
          <div className="trip-app-body">
            {page === STEPS.step1 && (
              <p>STEP 1 CONTENT</p>
            )}
            {page === STEPS.step2 && (
              <p>STEP 2 CONTENT</p>
            )}
            {page === STEPS.step3 && (
              <p>STEP 3 CONTENT</p>
            )}
            <ButtonGroup>
              <Button position={BUTTON_POSITION.LEFT} onClick={prevPage}>Prev</Button>
              <Button position={BUTTON_POSITION.RIGHT} onClick={nextPage}>Next</Button>
            </ButtonGroup>
          </div>
        </div>
        <div className="list-row"><div><b>Component: Stepper 2</b></div>
          <Stepper2 steps={steps2} onSelect={handleSelectStep2}></Stepper2>
          <div className="trip-app-body">
            {page2 === STEPS2.step1 && (
              <p>STEP 1 CONTENT</p>
            )}
            {page2 === STEPS2.step2 && (
              <p>STEP 2 CONTENT</p>
            )}
            {page2 === STEPS2.step3 && (
              <p>STEP 3 CONTENT</p>
            )}
            <ButtonGroup>
              <Button position={BUTTON_POSITION.LEFT} onClick={prevPage2}>Prev</Button>
              <Button position={BUTTON_POSITION.RIGHT} onClick={nextPage2}>Next</Button>
            </ButtonGroup>
            <ul>
              {steps2.map((step, i) => (
                <li key={i}>{step.title} = {step.completed.toString()}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="list-row"><div><b>Component: Table</b></div>
          <Table cols={4}>
            <Table.Row>
              <Table.Cell style={{ border: '1px solid green' }}>Cell 1x1</Table.Cell>
              <Table.Cell style={{ border: '1px solid green' }}>Cell 1x2</Table.Cell>
              <Table.Cell style={{ border: '1px solid green' }}>Cell 1x3</Table.Cell>
              <Table.Cell style={{ border: '1px solid green' }}>Cell 1x4</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colspan={2} style={{ border: '1px solid green' }}>Cell 2x(1-2)</Table.Cell>
              <Table.Cell style={{ border: '1px solid green' }}>Cell 2x3</Table.Cell>
              <Table.Cell style={{ border: '1px solid green' }}>Cell 2x4</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell style={{ border: '1px solid green' }}>Cell 3x1</Table.Cell>
              <Table.Cell colspan={2} style={{ border: '1px solid green' }}>Cell 3x(2-3)</Table.Cell>
              <Table.Cell style={{ border: '1px solid green' }}>Cell 3x4</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colspan={4} style={{ border: '1px solid green' }}>Cell 4x(1-4)</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colspan={3} style={{ border: '1px solid green' }}>Cell 5x(1-3)</Table.Cell>
              <Table.Cell style={{ border: '1px solid green' }}>Cell 5x4</Table.Cell>
            </Table.Row>
          </Table>
        </div>
      </div>
      <div className="trip-app-footer">
      </div>
    </div>
  )
}

export default UIExample;