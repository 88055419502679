import ClassHelper from '../ClassHelper';
import Payment from './Payment';

class PaymentDetails extends ClassHelper {
  constructor({ method, fullAmount, amount, paidAmount, partialValue, leftToPay, payments = [], refundAmount } = {}) {
    super(...arguments);
    this.method = method; // PaymentMethod
    this.fullAmount = fullAmount;
    this.amount = amount;
    this.paidAmount = paidAmount;
    this.partialValue = partialValue;
    this.leftToPay = leftToPay;
    this.payments = this.array(payments, Payment);
    this.refundAmount = refundAmount;
  }
}

export default PaymentDetails;