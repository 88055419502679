import FixtureAuth from './auth';
import FixtureUser from './user';
import { getJSON } from '../utils/json';

const Fixture = {
  auth: FixtureAuth,
  user: FixtureUser
};

export const callFixture = (fixture, apiParams) => {
  let params = {
    params: apiParams,
    store: getStore()
  };
  /* eslint-disable no-new-func */
  let response = getJSON(new Function('let store = this.store; let params = this.params; return `' + fixture + '`;').call(params), {});
  return { data: response };
};

export const getStore = () => {
  return store1;
};

const store1 = {
  users: [
    {
      "_id": "67394b748d00e2eb9bb1cf52",
      "profile": {
        "firstName": "Анатолий",
        "lastName": "Кашиер",
        "middleName": "Туристович",
        "birthday": "1980-01-04T00:00:00.000Z",
        "approved": true,
        "_id": "67394b748d00e2eb9bb1cf53",
        "fullName": "Ларин Георгий Иванович"
      },
      "phone": "+79010000004",
      "language": "ru",
      "deletedAt": null,
      "accounts": [
        {
          "account": "67394a639dcabbb37bb7deb8",
          "accountType": "trip-company",
          "roles": [
            "cashier"
          ],
          "status": "active",
          "_id": "67394b838d00e2eb9bb1d085"
        }
      ],
      "createdAt": "2024-11-17T01:48:36.259Z",
      "updatedAt": "2024-11-17T01:48:51.335Z",
    },
    {
      "_id": "67394b738d00e2eb9bb1cf49",
      "profile": {
        "firstName": "Борис",
        "lastName": "Коллекторов",
        "middleName": "Туристович",
        "birthday": "1980-01-01T00:00:00.000Z",
        "approved": true,
        "_id": "67394b738d00e2eb9bb1cf4a",
        "fullName": "Березин Александр Иванович"
      },
      "phone": "+79010000001",
      "language": "ru",
      "deletedAt": null,
      "accounts": [
        {
          "account": "67394a639dcabbb37bb7deb8",
          "accountType": "trip-company",
          "roles": [
            "collector"
          ],
          "status": "active",
          "_id": "67394b838d00e2eb9bb1d07f"
        }
      ],
      "createdAt": "2024-11-17T01:48:35.797Z",
      "updatedAt": "2024-11-17T01:48:51.295Z",
    },
    {
      "_id": {
        "$oid": "67394b738d00e2eb9bb1cf4c"
      },
      "profile": {
        "firstName": "Михаил",
        "lastName": "Гончаров",
        "middleName": "Иванович",
        "birthday": "1980-01-02T00:00:00.000Z",
        "approved": true,
        "_id": "67394b738d00e2eb9bb1cf4d",
        "fullName": "Гончаров Михаил Иванович"
      },
      "phone": "+79010000002",
      "language": "ru",
      "deletedAt": null,
      "accounts": [
        {
          "account": {
            "$oid": "67394a639dcabbb37bb7deb8"
          },
          "accountType": "trip-company",
          "roles": [
            "collector"
          ],
          "status": "active",
          "_id": {
            "$oid": "67394b838d00e2eb9bb1d081"
          }
        }
      ],
      "createdAt": {
        "$date": "2024-11-17T01:48:35.953Z"
      },
      "updatedAt": {
        "$date": "2024-11-17T01:48:51.316Z"
      },
      "__v": 1
    }
  ],
  accounts: [
    {
      account: "65ee54077c07cf3a770dcd95",
      accountType: "trip-company",
      roles: [
        "cashier",
        "driver",
        "guide",
        "collector"
      ],
      status: "active",
      id: "65eec46cc623fb4e1b5d6840",
      accountTitle: "TEST COMPANY 1",
      accountUrl: "kira-liza-monmatra"
    }
  ],
  excursions: [
    {
      id: "9a183cfe4ee8418fb69f60ded7a3722d",
      type: "bus",
      pickupType: "place",
      title: "Bus excursion 1",
      url: "http://bus.excursion.one",
      moderation: {
        status: "approved",
        comment: "haha"
      },
      categories: [{ _id: "3d88275c2a16401aa66c314b87e54e45", title: "Category 1" }],
      freeServices: [{ _id: "a3c5045f06794fda8b1a6e74f59c7ed3", title: "Service 1"}],
      canBeCancelledBefore: false,
      subdivisions: [{ subdivision: { _id: "86a8556627b848e59e15ccb712c3f468", title: "Subdivision 1" } }],
    }
  ]
};

export default Fixture;