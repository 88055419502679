import ClassHelper from '../ClassHelper';
import Price from '../price/Price';

class OrderStatPrice extends ClassHelper {
  constructor({ price, minAge, maxAge, noSeat, count, actualCount } = {}) {
    super(...arguments);
    this.price = this.ref(price, Price);
    this.minAge = minAge;
    this.maxAge = maxAge;
    this.noSeat = noSeat;
    this.count = count;
    this.actualCount = actualCount;
  }
}

export default OrderStatPrice;