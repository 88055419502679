import React from 'react';
import PropTypes from 'prop-types';
import Table from './Table';

export const LINE_MODE = {
  ONE: 'one',
  TWO: 'two'
};

const Field = ({ className = '', title = '', children, mode = LINE_MODE.TWO }) => {
  return (
    <>
      {(mode === LINE_MODE.TWO) && (
        <div className={`field ${className}`}>
          {title && (
            <div className="field-label">{title}</div>
          )}
          <div className="info-field-data">{children}</div>
        </div>
      )}
      {(mode === LINE_MODE.ONE) && (
        <Table className={`field ${className}`} cols={2}>
          <Table.Row>
            {title && (
              <Table.Cell className="field-label">{title}</Table.Cell>
            )}
            <Table.Cell className="info-field-data">{children}</Table.Cell>
          </Table.Row>
        </Table>
      )}
    </>
  );
}

Field.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string
};

export default Field;