import { ClassTimestampsHelper } from '../ClassHelper';
import Account from '../account/Account';
import ObjectClass from '../object/ObjectClass';

class AccountObject extends ClassTimestampsHelper {
  constructor({ account, object, title, status } = {}) {
    super(...arguments);
    this.account = this.ref(account, Account);
    this.object = this.obj(object, ObjectClass);
    this.title = title;
    this.status = status; // Status
  }
}

export default AccountObject;