import { ClassTimestampsHelper } from '../ClassHelper';
import CategorySeo from './CategorySeo';

class Category extends ClassTimestampsHelper {
  constructor({ title, pageTitle, description, url, image, seo, status } = {}) {
    super(...arguments);
    this.title = title;
    this.pageTitle = pageTitle;
    this.description = description;
    this.url = url;
    this.image = image;
    this.seo = this.obj(seo, CategorySeo);
    this.status = status; // Status
  }
}

export default Category;