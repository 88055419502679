import ClassHelper from '../ClassHelper';

class AccountSettings extends ClassHelper {
  constructor({ startSellingBus, startSellingShip } = {}) {
    super(...arguments);
    this.startSellingBus = startSellingBus;
    this.startSellingShip = startSellingShip;
  }
}

export default AccountSettings;