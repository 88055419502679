import { ClassTimestampsHelper } from '../ClassHelper';
import Account from '../account/Account';
import Excursion from '../excursion/Excursion';
import Order from '../order/Order';

class Reward extends ClassTimestampsHelper {
  constructor({ type, account, excursion, order, date, amount } = {}) {
    super(...arguments);
    this.type = type; // RewardType
    this.account = this.ref(account, Account);
    this.excursion = this.ref(excursion, Excursion);
    this.order = this.ref(order, Order);
    this.date = this.obj(date, Date);
    this.amount = amount;
  }
}

export default Reward;