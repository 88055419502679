import { env } from "./util";

export const LOG = {
  ERROR:'ERROR', DEBUG:'DEBUG', DEBUG_DECODER:'DEBUG_DECODER', WARN:'WARN', INFO:'INFO', EXEC:'EXEC', STACK:'STACK', CHECK:'CHECK', OTHER:'OTHER', FROM: 'FROM',
  COLOR: { ERROR:'red', DEBUG:'gray', DEBUG_DECODER:'gray', WARN:'orange', INFO:'#87CEFA', EXEC:'green', STACK:'red', CHECK:'maroon', OTHER:'#87CEFA', FROM:'#87CEFA' }
};

function log() {
  if (this instanceof log && !this.__alreadyCreated) { // is constructor
    this.__alreadyCreated = true;
    this.project = typeof arguments[0] === 'string' ? arguments[0] : (typeof arguments[0] === 'object' && typeof arguments[0].name === 'string' ? arguments[0].name : '');
    let showArg = typeof arguments[0] === 'object' && arguments[0].show instanceof Array ? arguments[0].show : [LOG.ERROR, LOG.WARN, LOG.INFO];
    this.showLevel = {
      ERROR: showArg.indexOf(LOG.ERROR) >= 0,
      DEBUG: showArg.indexOf(LOG.DEBUG) >= 0,
      WARN: showArg.indexOf(LOG.WARN) >= 0,
      INFO: showArg.indexOf(LOG.INFO) >= 0,
      EXEC: showArg.indexOf(LOG.EXEC) >= 0,
      STACK: showArg.indexOf(LOG.STACK) >= 0,
      CHECK: showArg.indexOf(LOG.CHECK) >= 0,
      OTHER: showArg.indexOf(LOG.OTHER) >= 0,
      FROM: showArg.indexOf(LOG.FROM) >= 0
    }
    this.showStack = typeof arguments[0] === 'object' && typeof arguments[0].showStack === 'boolean' ? arguments[0].showStack : true;
    this.showDate = typeof arguments[0] === 'object' && typeof arguments[0].showDate === 'boolean' ? arguments[0].showDate : true;
    this.showFile = typeof arguments[0] === 'object' && typeof arguments[0].showFile === 'boolean' ? arguments[0].showFile : true;
    this.logID = typeof arguments[0] === 'object' && typeof arguments[0].logID === 'string' ? arguments[0].logID : log.logID;
    this.logListID = typeof arguments[0] === 'object' && typeof arguments[0].logListID === 'string' ? arguments[0].logListID : log.logListID;
    this.commandID = typeof arguments[0] === 'object' && typeof arguments[0].commandID === 'string' ? arguments[0].commandID : log.commandID;
    this.commandExecuteID = typeof arguments[0] === 'object' && typeof arguments[0].commandExecuteID === 'string' ? arguments[0].commandExecuteID : log.commandExecuteID;
    this.commandTextID = typeof arguments[0] === 'object' && typeof arguments[0].commandTextID === 'string' ? arguments[0].commandTextID : log.commandTextID;
    log.instances[new Date().getTime()] = this;
  } else {
    let that = this || log;

    if (!that.__alreadyCreated) {
      that.project = that.project || '';
      that.showLevel = {
        ERROR: log.showLevel && !!log.showLevel[LOG.ERROR],
        DEBUG: log.showLevel && !!log.showLevel[LOG.DEBUG],
        WARN: log.showLevel && !!log.showLevel[LOG.WARN],
        INFO: log.showLevel && !!log.showLevel[LOG.INFO],
        EXEC: log.showLevel && !!log.showLevel[LOG.EXEC],
        STACK: log.showLevel && !!log.showLevel[LOG.STACK],
        CHECK: log.showLevel && !!log.showLevel[LOG.CHECK],
        OTHER: log.showLevel && !!log.showLevel[LOG.OTHER],
        FROM: log.showLevel && !!log.showLevel[LOG.FROM]
      }
      that.showStack = true;
      that.logID = log.logID;
      that.logListID = log.logListID;
      that.commandID = log.commandID;
      that.commandExecuteID = log.commandExecuteID;
      that.commandTextID = log.commandTextID;
    }

    let level = LOG.INFO;
    let logArgs = [];

    if (arguments.length === 1) {
      logArgs = Array.from(arguments);
    } else if (LOG[arguments[0]] === undefined) {
      logArgs = Array.from(arguments);
    } else {
      level = arguments[0];
      logArgs = Array.from(arguments).slice(1);
    }

    if (that.showLevel[level]) {
      let time = (that.showDate ? (new Date()).toISOString().replace('T', ' ').replace('Z', '') : (new Date()).toISOString().split('T')[1].replace('Z', '')) + ' ';
      let head = '%c' + time + ' %c' + level + ' -> %c' + (that.project ? '(' + that.project + ')' : '') + '%c';
      if (that.showStack) {
        head += ' [' + log.getStack(3, level === LOG.FROM ? true : false, logArgs && typeof logArgs[1] === 'number' ? logArgs[1] : 1, that.showFile) + ']';
      }
      head += '%c';
      logArgs.unshift('color:' + LOG.COLOR[level]);
      logArgs.unshift('color:#87CEFA');
      logArgs.unshift('color:grey');
      logArgs.unshift('color:' + LOG.COLOR[level]);
      logArgs.unshift('color:grey');
      logArgs.unshift(head);

      if ((level === LOG.ERROR || level === LOG.STACK) && console && typeof console.error === 'function') {
        if (log.nativeError)
          log.nativeError(logArgs);
        else
          console.error.apply(console, logArgs);
      } else if (level === LOG.WARN && console && typeof console.warn === 'function')
        console.warn.apply(console, logArgs);
      else if (level === LOG.DEBUG && console && typeof console.debug === 'function')
        console.debug.apply(console, logArgs);
      else if (level === LOG.INFO && console && typeof console.info === 'function')
        console.info.apply(console, logArgs);
      else if (console && typeof console.log === 'function')
        console.log.apply(console, logArgs);
    }
  }
}
log.prototype.info  = function(){ log.apply(this, [LOG.INFO].concat(Array.from(arguments))); };
log.prototype.warn  = function(){ log.apply(this, [LOG.WARN].concat(Array.from(arguments))); };
log.prototype.error = function(){ log.apply(this, [LOG.ERROR].concat(Array.from(arguments))); };
log.prototype.debug = function(){ log.apply(this, [LOG.DEBUG].concat(Array.from(arguments))); };
log.prototype.exec  = function(){ log.apply(this, [LOG.EXEC].concat(Array.from(arguments))); };
log.prototype.stack = function(){ log.apply(this, [LOG.STACK].concat(Array.from(arguments))); };
log.prototype.check = function(){ log.apply(this, [LOG.CHECK].concat(Array.from(arguments))); };
log.prototype.other = function(){ log.apply(this, [LOG.OTHER].concat(Array.from(arguments))); };
log.prototype.from  = function(){ log.apply(this, [LOG.FROM].concat(Array.from(arguments))); };

log.info  = function(){ log.apply(log, [LOG.INFO].concat(Array.from(arguments))); };
log.warn  = function(){ log.apply(log, [LOG.WARN].concat(Array.from(arguments))); };
log.error = function(){ log.apply(log, [LOG.ERROR].concat(Array.from(arguments))); };
log.debug = function(){ log.apply(log, [LOG.DEBUG].concat(Array.from(arguments))); };
log.exec  = function(){ log.apply(log, [LOG.EXEC].concat(Array.from(arguments))); };
log.stack = function(){ log.apply(log, [LOG.STACK].concat(Array.from(arguments))); };
log.check = function(){ log.apply(log, [LOG.CHECK].concat(Array.from(arguments))); };
log.other = function(){ log.apply(log, [LOG.OTHER].concat(Array.from(arguments))); };
log.from  = function(){ log.apply(log, [LOG.FROM].concat(Array.from(arguments))); };

log.logID = 'log';
log.logListID = 'logList';
log.commandID = 'consoleCommand';
log.commandExecuteID = 'executeConsoleCommand';
log.commandTextID = 'consoleCommandText';
log.NEXT_LINE = 1;
log.showLevel = {
  ERROR: true,
  DEBUG: env('REACT_APP_LOG_LEVEL', '').toUpperCase() === 'DEBUG',
  WARN: true,
  INFO: true,
  EXEC: false,
  STACK: false,
  CHECK: false,
  OTHER: false,
  FROM: true
}
log.instances = {};
log.alias = {};

log.get = function(name) {
  if (!log.alias[name]) {
    log.alias[name] = new log({ name: name, show: [LOG.ERROR, LOG.WARN, LOG.INFO, LOG.DEBUG] });
  }
  return log.alias[name];
}

log.getStack = function(line, from, next, showFile) {
  line = line || 1;
  next = next || log.NEXT_LINE || 1;
  let errstack = (new Error()).stack;
  let stack = errstack.match(/at (\S+)( \(\S+\)|)/g);
  let func = null;
  if (stack) {
    func = stack && stack[line] ? stack[line].match(/at (\S+) \((\S+):(\d+):(\d+)\)/) : '';
    if (from) {
      let fromFunc = null;
      let loadNextLine = -3;
      for (let i = 0; i < stack.length; ++i) {
        let stack_line = stack && stack[i] ? stack[i].match(/at (\S+) \((\S+):(\d+):(\d+)\)/) : '';
        loadNextLine++;
        if (stack_line && loadNextLine && loadNextLine <= next) {
          if (loadNextLine > 0) {
            fromFunc = (fromFunc ? '\n' : '') + (showFile ? stack_line[2] + ' @ ' : '') + stack_line[1] + ':' + stack_line[3];
          }
        }
      }
      return fromFunc;
    } else if (func && func.length === 5) {
      return (showFile ? func[2] + ' @ ' : '') + func[1] + ':' + func[3];
    } else if (!func) {
      func = errstack.match(/at (\S+):(\d+):(\d+)/);
      return func ? func[1] + ':' + func[2] : '';
    } else {
      return '';
    }
  } else {
    stack = errstack.match(/(\S+)@(\S+):(\d+):(\d+)/g);
    func = stack && stack[line] ? stack[line].match(/(\S+)@(\S+):(\d+):(\d+)/) : '';
    if (from) {
      let fromFunc = null;
      let loadNextLine = -3;
      for (let i = 0; i < stack.length; ++i) {
        let stack_line = stack && stack[i] ? stack[i].match(/(\S+)@(\S+):(\d+):(\d+)/) : '';
        loadNextLine++;
        if (stack_line && loadNextLine && loadNextLine <= next) {
          if (loadNextLine > 0) {
            fromFunc = (fromFunc ? '\n' : '') + (showFile ? stack_line[2] + ' @ ' : '') + stack_line[1] + ':' + stack_line[3];
          }
        }
      }
      return fromFunc;
    } else if (func && func.length === 5) {
      return func ? (showFile ? func[2] + ' @ ' : '') + func[1] + ':' + func[3] : '';
    } else if (!func) {
      func = errstack.match(/@([\S ]+):(\d+):(\d+)/);
      return func ? func[1] + ':' + func[2] : '';
    } else {
      return '';
    }
  }
}

const style = {
  bold: 'font-weight: bold; color: black',
  normal: 'font-weight: normal',
  count: 'font-weight: normal; color: mediumvioletred',
  number: 'color: forestgreen',
  string: 'color: dodgerblue',
  null: 'color: red',
  date: 'color: brown',
  undefined: 'color: violet'
};

const _print = (o, name) => {
  if (typeof o === 'function') {
  } else if (o instanceof Array) {
    console.group(`%c${name}%c %c{${o.length}}%c`, style.bold, style.normal, style.count, style.normal);
    for (let i = 0; i < o.length; ++i) {
      _print(o[i], i);
    }
    if (o.length === 0) {
      console.log(`%cEMPTY%c`, style.count, style.normal);
    }
    console.groupEnd();
  } else if (o instanceof Date) {
    let date = new Date().toISOString().replace(/[TZ]/g, ' ');
    console.log(`%c${name}%c = %c${date}%c`, style.bold, style.normal, style.date, style.normal);
  } else if (typeof o === 'object') {
    if (o) {
      console.group(`%c${name}%c`, style.bold, style.normal);
      for (let n in o) {
        _print(o[n], n);
      }
      console.groupEnd();
    } else {
      console.log(`%c${name}%c = %cnull%c`, style.bold, style.normal, style.null, style.normal);
    }
  } else if (o === undefined) {
    console.log(`%c${name}%c = %c${o}%c`, style.bold, style.normal, style.undefined, style.normal);
  } else {
    console.log(`%c${name}%c = %c${o}%c`, style.bold, style.normal, typeof o === 'string' ? style.string : typeof o === 'number' ? style.number : style.normal, style.normal);
  }
};

log.print = function() {
  let name = arguments.length === 1 ? '' : arguments[0];
  let o = arguments.length === 1 ? arguments[0] : arguments[1];

  _print(o, name);
};

export default log;