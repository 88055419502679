import { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RepositoryContext } from '../store/RepositoryProvider';

const RepositoryModel = ({ type, uid, render, onNotFound, onError, forward=false }) => {
  const repository = useContext(RepositoryContext);

  const [model, setModel] = useState(null);
  const [error, setError] = useState(null);
  const [notFound, setNotFound] = useState(false);

  const loadModel = useCallback(async () => {
    repository.getModel(type, uid, true, forward).then((model) => {
      setModel({ ...model });
      setNotFound(false);
      setError(null);
    // } else if (repository.load[type]) {
    //   repository.load[type]({ id: uid }).then((model) => {
    //     setModel(model);
    //     setNotFound(false);
    //     setError(null);
    //   }).catch((e) => {
    //     setModel(null);
    //     let errorCode = typeof e === 'object' ? e.error : e;
    //     if (errorCode === APICode.NOT_FOUND) {
    //       setNotFound(true);
    //       setError(null);
    //     } else {
    //       setError(e);
    //       setNotFound(false);
    //     }
    //   });
    }).catch((error) => {
      setModel(null);
      setNotFound(true);
      setError(null);
    });
  }, [uid]);

  useEffect(() => {
    loadModel();
  }, [uid]);

  useEffect(() => {
    if (!model || !repository.at(type, uid) || (repository.at(type, uid).updatedAt !== model.updatedAt)) {
      loadModel();
    }
  }, [repository]);

  return (
    <>
      {model && render(model.value || {})}
      {notFound && (typeof onNotFound === 'function' ? onNotFound() : onNotFound)}
      {error && (typeof onError === 'function' ? onError(error) : onError)}
    </>
  );
};


RepositoryModel.propTypes = {
  type: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
  onNotFound: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func
  ]),
  onError: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func
  ])
};

export default RepositoryModel;