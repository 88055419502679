import { useState, createContext, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from './UserProvider';
import { STEPPER_ITEM_STATUS } from '../components/Stepper';
import { APICode, DynamicPriceType, SellerType } from '../models/types';
import useAPI from '../hooks/useAPI';
import API from '../server/api';
import Message from '../models/Message';
import Order from '../models/order/Order';
import { RepositoryContext } from './RepositoryProvider';
import { ALERT_TYPE } from '../components/Alert';
import ReportParams from '../models/report/ReportParams';
import UserAccount from '../models/user/UserAccount';
import Report from '../models/report/Report';
import User from '../models/user/User';

export const OrderContext = createContext();

export const STEPS = {
  common_info: 0,
  client_info: 1,
  payment_booking: 2,
  print: 3
};

class Step
{
  constructor({ id, title, onSelect, onComplete, onError, validate }) {
    this.id = id;
    this.title = title;
    this.status = STEPPER_ITEM_STATUS.WAIT;
    this.errors = [];
    this.onSelect = typeof onSelect === 'function' ? onSelect : () => {};
    this.onComplete = typeof onComplete === 'function' ? onComplete : () => {};
    this.onError = typeof onError === 'function' ? onError : () => {};
    this.validate = typeof validate === 'function' ? validate : () => {};
  }
};

const initialState = {
  schedules: [],
  common_info: {
    excursion: null,
    schedule: null,
    date: null,
    passengers: [],
    passports: [],
    wishes: [],
    place: null,
    put_passport: true,
    errors: []
  },
  client_info: {
    phone: '',
    contact_phones: [],
    name: '',
    first_name: '',
    last_name: '',
    middle_name: '',
    birthday: '',
    discount: {},
    user: null,
    errors: []
  },
  payment_booking: {
    payment_method: null,
    comment: null,
    errors: []
  },
  payment: {},
  order: null,
  steps: []
};

const initSteps = (t) => {
  return Object.keys(STEPS).map((step) => new Step({ title: t(`order:steps.${step}`) }));
};

export const OrderProvider = (props) => {
  const {t} = useTranslation(['common', 'order']);

  const [page, setPage] = useState(0);
  const [orderInfo, setOrderInfo] = useState(initialState);
  const [prices, setPrices] = useState([]);
  const [steps, setSteps] = useState(initSteps(t));
  const [updated, setUpdated] = useState(initSteps(t));

  const { callApi } = useAPI();

  const userInfo = useContext(UserContext);
  const repository = useContext(RepositoryContext);

  const update = () => {
    setUpdated(new Date().getTime());
  };

  const useUpdated = useCallback(() => {
    return [updated, update];
  }, [updated]);

  const getUpdated = () => {
    return updated;
  };

  const nextPage = () => {
    let newPage = page + 1;
    if (newPage < steps.length && newPage >= 0) {
      steps[page].status = STEPPER_ITEM_STATUS.COMPLETED;
      setSteps([ ...steps ]);
      setPage(newPage);
    }
  };

  const prevPage = () => {
    let newPage = page - 1;
    if (newPage < steps.length && newPage >= 0) {
      setPage(newPage);
    }
  };

  const reset = () => {
    setSteps(initSteps(t));
    setPage(0);
    setOrderInfo(initialState);
    update();
  };

  const selectPage = (idx) => {
    setPage(idx);
  };

  const setCompleteStep = (idx) => {
    steps[idx].status = STEPPER_ITEM_STATUS.COMPLETED;
    setSteps([ ...steps ]);
  };

  const setCommonInfo = (prop) => {
    setOrderInfo((prevState) => { prevState.common_info = { ...prevState.common_info, ...prop }; return prevState; });
    update();
  };

  const getCommonInfo = () => {
    return orderInfo.common_info;
  };

  const useCommonInfo = () => {
    return [orderInfo, setOrderInfo];
  };

  const getCommonInfoAmount = useCallback(() => {
    return orderInfo.common_info.passengers.reduce((amount, passenger) => {
      let price = prices.find((price) => price.price.id === passenger.price) || { amount: 0 };
      return amount + passenger.count * price.amount;
    }, 0);
  }, [orderInfo.common_info.passengers, prices]);

  const setClientInfo = (prop) => {
    setOrderInfo((prevState) => { prevState.client_info = { ...prevState.client_info, ...prop }; return prevState; });
    update();
  };

  const getClientInfo = () => {
    return orderInfo.client_info;
  };

  const getClientInfoAmount = useCallback(() => {
    let amount = getCommonInfoAmount();
    return orderInfo.client_info.discount ? (orderInfo.client_info.discount.type === DynamicPriceType.ABSOLUTE ? (amount - orderInfo.client_info.discount.value) : (amount * (1 - orderInfo.client_info.discount.value / 100).toFixed(2))) : amount;
  }, [orderInfo.client_info.discount, orderInfo.common_info.passengers], prices);

  const setPaymentBooking = (prop) => {
    setOrderInfo((prevState) => { prevState.payment_booking = { ...prevState.payment_booking, ...prop }; return prevState; });
    update();
  };

  const getPaymentBooking = () => {
    return orderInfo.payment_booking;
  };

  const getPaymentBookingAmount = () => {
    return getClientInfoAmount();
  };

  const setOrder = (prop) => {
    setOrderInfo((prevState) => { prevState.order = { ...prevState.order, ...prop }; return prevState; });
    update();
  };

  const getOrder = () => {
    return orderInfo.order;
  };

  const getTotalAmount = () => {
    return getCommonInfoAmount();// + getClientInfoAmount() + getPaymentBookingAmount();
  };

  const addError = (step, code, title, message) => {
    setOrderInfo((prevState) => { prevState[step].errors.push( new Message({ code, title, message }) ); return prevState; });
    update();
  };

  const clearError = (step, uid) => {
    if (uid) {
      setOrderInfo((prevState) => { let idx = prevState[step].errors.findIndex(error => error.uid === uid); if (idx >= 0) prevState[step].errors.splice(idx, 1); return prevState; });
    } else {
      setOrderInfo((prevState) => { prevState[step].errors = []; return prevState; });
    }
    update();
  };

  const validate = async (step, info) => {
    switch(step) {
      case STEPS.common_info: {
        let common_info = info ? { ...orderInfo.common_info, ...info } : { ...orderInfo.common_info };
        common_info.errors = [];
        if (!common_info.excursion) {
          common_info.errors.push(new Message({ code: 'excursion_not_set', title: t('order:common_info.error.excursion_not_set'), type: ALERT_TYPE.ERROR }));
        } else {
          let excursion = await repository.getModel('Excursion', common_info.excursion);

          if (excursion && excursion.passportRequired && common_info.put_passport && !(common_info.passengers.reduce((count, passenger) => count + passenger.passports.reduce((count, passport) => count + (passport && passport.fullName && passport.number ? 1 : 0), 0), 0) === common_info.passengers.reduce((count, passenger) => count + passenger.count, 0))) {
            common_info.errors.push(new Message({ code: 'passports_not_set', title: t('order:common_info.error.passports_not_set'), type: ALERT_TYPE.ERROR }));
          }
        }
        if (!common_info.passengers.length) {
          common_info.errors.push(new Message({ code: 'passengers_not_set', title: t('order:common_info.error.passengers_not_set'), type: ALERT_TYPE.ERROR }));
        }
        if (!common_info.date) {
          common_info.errors.push(new Message({ code: 'date_not_set', title: t('order:common_info.error.date_not_set'), type: ALERT_TYPE.ERROR }));
        }
        if (!common_info.time) {
          common_info.errors.push(new Message({ code: 'time_not_set', title: t('order:common_info.error.time_not_set'), type: ALERT_TYPE.ERROR }));
        }
        if (!common_info.place) {
          common_info.errors.push(new Message({ code: 'place_not_set', title: t('order:common_info.error.place_not_set'), type: ALERT_TYPE.ERROR }));
        }
        setCommonInfo(common_info);

        return common_info.errors;
      }
      case STEPS.client_info: {
        let client_info = info ? { ...orderInfo.client_info, ...info } : { ...orderInfo.client_info };
        client_info.errors = [];
        return client_info.errors;
      }
      case STEPS.payment_booking: {
        let payment_booking = info ? { ...orderInfo.payment_booking, ...info } : { ...orderInfo.payment_booking };
        payment_booking.errors = [];
        return payment_booking.errors;
      }
      default: {}
    }
    return [];
  };


  const createOrder = () => {
    return new Promise(async (resolve, reject) => {
      let subdivision = userInfo.getSession().subdivision;
      let passports = [];
      orderInfo.common_info.passengers.forEach((passenger) => {
        Object.values(passenger.passports).forEach((passport) => {
          passports.push({ number: passport.number, fullName: passport.fullName });
        });
      });

      if (subdivision) {
        const response = await callApi(API.order.createOrder, {
          user: orderInfo.client_info.user,
          userInfo: {
            phone: orderInfo.client_info.phone,
            name: `${orderInfo.client_info.first_name} ${orderInfo.client_info.last_name} ${orderInfo.client_info.middle_name}`.trim()
          },
          excursion: orderInfo.common_info.excursion,
          subdivision: subdivision,
          schedule: orderInfo.common_info.schedule,
          passengers: orderInfo.common_info.passengers.map((passenger) => ({ price: passenger.price, count: passenger.count, ages: Object.values(passenger.ages) || [] })),
          sellerType: SellerType.CASHIER,
          pointId: userInfo.getSession().point,
          paymentMethod: orderInfo.payment_booking.payment_method,
          contactPhones: [ ...orderInfo.client_info.contact_phones ],
          wishes: orderInfo.common_info.wishes,
          place: orderInfo.common_info.place,
          // address: orderInfo.client_info.place.address,
          passports: passports,
          comment: orderInfo.payment_booking.comment,
          discountId: orderInfo.client_info.discount ? orderInfo.client_info.discount.id : ''
        });
        // - `user` - покупатель заказа
        // - `excursion` - ID экскурсии, которую заказывает пользователь
        // - `subdivision` - ID подразделения, к которому относится заказ
        // - `schedule` - ID расписания
        // - `passengers` - массив пассажиров (см. ниже)
        // - `sellerType` - тип продавца (см. ниже)
        // - `pointId` - ID точки посадки (обязателен если sellerType = cashier)
        // - `paymentMethod` - метод платежа (см. ниже)
        // - `contactPhones` - массив телефонов для связи - **необязательный параметр**
        // - `wishes` - массив пожеланий (см. ниже) - **необязательный параметр**
        // - `place` - ID места посадки (см. ниже) - **необязательный параметр**
        // - `address` - адрес посадки - **необязательный параметр**
        // - `passports` - массив паспортов пассажиров (см. ниже) - **необязательный параметр**
        // - `comment` - комментарий к заказу - **необязательный параметр**
        // - `discountId` - ID скидки / промокода - **необязательный параметр**

        if (response.error) {
          console.error('New Order errro:', response);
          return reject(response);
        } else {
          resolve(new Order(response.data));
        }
      } else {
        console.error('New Order subdivision_is_null');
        reject({ error: 'subdivision_is_null' });
      }
    });
  };

  const isNewPhone = async () => {
    if (orderInfo.client_info.phone) {
      let response = await callApi(API.auth.checkPhone, { phone: orderInfo.client_info.phone });
      return response.data === APICode.USER_NOT_FOUND;
    }
    return false;
  };

  const inviteUser = async () => {
    if (orderInfo.client_info.phone) {
      let response = await callApi(API.order.createClient, {
        phone: orderInfo.client_info.phone,
        firstName: orderInfo.client_info.first_name,
        lastName: orderInfo.client_info.last_name,
        middleName: orderInfo.client_info.middle_name,
        birthday: orderInfo.client_info.birthday
      });
      if (response.error) {
        return response;
      } else {
        let user = new User(response.data);
        return user;
      }
    }

    return false;
  };

  const getUserAccount = async () => {
    if (orderInfo.client_info.phone) {
      let response = await callApi(API.account.users, new ReportParams({ filters: { phone: orderInfo.client_info.phone } }));
      if (response.error) {
        return response;
      } else {
        let report = new Report(response.data, UserAccount);
        let userAccountList = report.rows;
        let account = null;
        if (userAccountList.length) {
          account = userAccountList[0];
        }
        return account;
      }
    }
    return false;
  };

  const askUser = async (accountId) => {
    if (accountId) {
      let response = await callApi(API.user.user, { id: accountId });
      if (response.error) {
        return response;
      } else {
        let user = new User(response.data);
        return user;
      }
    }
    return false;
  };

  return (
    <OrderContext.Provider
      value={{
        page,
        steps,
        nextPage,
        prevPage,
        reset,
        selectPage,
        setCompleteStep,

        setCommonInfo,
        getCommonInfo,
        useCommonInfo,
        setClientInfo,
        getClientInfo,
        setPaymentBooking,
        getPaymentBooking,
        setOrder,
        getOrder,
        useUpdated,
        getUpdated,

        addError,
        clearError,

        getCommonInfoAmount,
        getClientInfoAmount,
        getPaymentBookingAmount,
        getTotalAmount,

        validate,
        prices,
        setPrices,

        createOrder,
        isNewPhone,
        inviteUser,
        getUserAccount,
        askUser
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
};
