import { useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ImageGallery from '../../components/ImageGallery';
import Info from '../../components/Info';
import Table from '../../components/Table';
import InfoDescription from '../../components/InfoDescription';
import Price, { CURRENCY } from '../../components/Price';
import RepositoryModel from '../../components/RepositoryModel';

const ExcursionInfo = ({ excursion }) => {
  const {t} = useTranslation(['common', 'excursion']);

  useEffect(() => {
    if (excursion) {
      excursion.subdivisions.forEach((subdivision) => {
        // subdivision.places.forEach(async (place) => {
        //   if (place.place) {
        //     place.place = await userInfo.fetch.place(typeof place.place === 'string' ? place.place : place.place.id);
        //     setPlaces(places + 1);
        //   }
        // });
      });
    }
  }, []);

  return (
    <div className="trip-app">
      <div className="trip-app-body">
        {excursion && (
          <>
            <ImageGallery list={excursion.images} />
            {excursion.subdivisions.map((subdivision) => (
              <Table key={subdivision.id}>
                <Table.Row>
                  <Info title={t('excursion:excursion_info.duration')}>
                    {t('common:duration.day.day', {count: subdivision.getDuration().days})}  {t('common:duration.hour.hour', {count: subdivision.getDuration().hours})}
                  </Info>
                </Table.Row>
                <Table.Row>
                  <Info title={t('excursion:excursion_info.type')}>
                    {t(`excursion:type.${excursion.type}`)}
                  </Info>
                </Table.Row>
                <Table.Row delim={true} />
                <Table.Row>
                  <div className="header-line">{t('excursion:excursion_info.price_on_place')}</div>
                  <p>{t('excursion:excursion_info.price_info')}</p>
                </Table.Row>

                {subdivision.objects && subdivision.objects.map((obj, i) => (
                  <RepositoryModel type="AccountObject" uid={obj.id}
                    render={(accobj) => (
                      <InfoDescription key={i} description={accobj.object.address}>
                        {accobj.title}
                      </InfoDescription>
                    )}
                  />
                ))}

                {subdivision.places && subdivision.places.map((place) => (
                  place.place && (
                    <Fragment key={place.id}>
                      {/* <Table.Row>
                        <InfoDescription description={place.place.title}>
                          {place.place.address}
                        </InfoDescription>
                      </Table.Row> */}
                      <Table cols={3}>
                        <Table.Row>
                          {subdivision.prices && subdivision.prices.map((price, i) => (
                            <Table.Cell key={i} className="price">
                              <InfoDescription description={price.price.title}>
                                <Price currency={CURRENCY.RUB} value={price.amount} />
                              </InfoDescription>
                            </Table.Cell>
                          ))}
                        </Table.Row>
                        <Table.Row delim={true} />
                      </Table>
                    </Fragment>
                  )
                ))}
              </Table>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

ExcursionInfo.propTypes = {
  excursion: PropTypes.object,
};

export default ExcursionInfo;