import ClassHelper from '../ClassHelper';

class ExcursionPrint extends ClassHelper {
  constructor({ title, price, order } = {}) {
    super(...arguments);
    this.title = title;
    this.price = price;
    this.order = order;
  }
}

export default ExcursionPrint;