import PropTypes from 'prop-types';
import ErrorHint from './ErrorHint';

export const CURRENCY = {
  RUB: 'RUB',
  EUR: 'EUR',
  USD: 'USD'
};

export const FIXED = {
  RUB: 2,
  EUR: 2,
  USD: 2
};

export const CURRENCY_SYMBOL = {
  RUB: String.fromCharCode(8381),
  EUR: String.fromCharCode(8364),
  USD: String.fromCharCode(36)
};

const getFixedPrice = (price, fix) => {
  price = price.toFixed(fix);
  return (price - ~~price) > 0 ? price : ~~price;
};

const Price = ({ value = 0, currency = CURRENCY.RUB, className = '', delim = ' ', onClick }) => {
  const symbol = CURRENCY_SYMBOL[currency.toUpperCase()] || '';

  const numberWithSpaces = () => {
    let parts = (typeof value === 'number' ? getFixedPrice(value, FIXED[currency]) : 0 ).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, delim);
    return parts.join(".");
  }

  return (
    <span className={`currency ${className}`} onClick={onClick}>{numberWithSpaces()}{symbol}</span>
  );
};

Price.propTypes = {
  className: PropTypes.string,
  delim: PropTypes.string,
  value: PropTypes.number.isRequired,
  currency: PropTypes.string,
  onClick: PropTypes.func
};

const Input = ({ className = '', currency = CURRENCY.RUB, min, max, value, note, error, onInput, onChange }) => {
  return (
    <>
      <div className="input-field input-group">
        {note && (
          <span className='input-note'>{note}</span>
        )}
        <input type="number" className={`form-control ${className}`} min={min} max={max} onInput={(e) => onInput && onInput(e.target.value)} onChange={(e) => onChange && onChange(e.target.value)} defaultValue={value} />
        <span className="input-group-text">{CURRENCY_SYMBOL[currency.toUpperCase()]}</span>
      </div>

      {error && (
        <ErrorHint error={error} />
      )}
    </>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  note: PropTypes.string,
  error: PropTypes.string,
  onInput: PropTypes.func,
  onChange: PropTypes.func,
  currency: PropTypes.string
};

Price.Input = Input;

const Text = ({ value = 0, currency = CURRENCY.RUB, delim = ' ' }) => {
  const symbol = CURRENCY_SYMBOL[currency.toUpperCase()] || '';

  const numberWithSpaces = () => {
    if (typeof value === 'string') {
      value = Number(value);
    }
    let parts = (typeof value === 'number' ? getFixedPrice(value, FIXED[currency]) : 0 ).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, delim);
    return parts.join(".");
  }

  return `${numberWithSpaces()}${symbol}`;
};

Text.propTypes = {
  delim: PropTypes.string,
  value: PropTypes.number.isRequired,
  currency: PropTypes.string
};

Price.Text = Text

export default Price;