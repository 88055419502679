import ExcursionPrice from '../excursion/ExcursionPrice';
import PassengerActualPrice from './PassengerActualPrice';

class Passengers extends ExcursionPrice {
  constructor({ count, actualCount, ages = [], actualPrice } = {}) {
    super(...arguments);
    this.count = count;
    this.actualCount = actualCount;
    this.ages = this.array(ages, Number);
    this.actualPrice = this.obj(actualPrice, PassengerActualPrice);
  }
}

export default Passengers;