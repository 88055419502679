import PropTypes from 'prop-types';

const Link = ({ id, className = '', action, src, children }) => {
  return (
    <>
      {src ?
        <a id={id} href={src} className={`link-btn ${className}`}>{children}</a>
        :
        <span id={id} onClick={action} className={`link-btn ${className}`}>{children}</span>
      }
    </>
  );
};

Link.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string,
  action: PropTypes.func
};

export default Link;