import ClassHelper from '../ClassHelper';
import BusPlace from './BusPlace';

class BusFloor extends ClassHelper {
  constructor({ countSeats, countRows, countCols, places = [] } = {}) {
    super(...arguments);
    this.countSeats = countSeats;
    this.countRows = countRows;
    this.countCols = countCols;
    this.places = this.array(places, BusPlace);
  }
}

export default BusFloor;