import ClassHelper from '../ClassHelper';

class Payment extends ClassHelper {
  constructor({ type, date, amount, method, comment, meta } = {}) {
    super(...arguments);
    this.type = type; // PaymentType
    this.date = this.obj(date, Date);
    this.amount = amount;
    this.method = method; // PaymentMethod
    this.comment = comment;
    this.meta = meta; // Object
  }
}

export default Payment;