import ClassHelper from '../ClassHelper';

class Limit extends ClassHelper {
  constructor({ value, cntSeats, stopSelling = true, forceStopSelling = false } = {}) {
    super(...arguments);
    this.value = value;
    this.cntSeats = cntSeats;
    this.stopSelling = !!stopSelling;
    this.forceStopSelling = !!forceStopSelling;
  }
}

export default Limit;