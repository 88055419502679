import axios from 'axios';
import Logger from '../utils/logger';
import { FetchMethod } from './useAPI';
import { AccountType, APICode } from '../models/types';
import networkState from '../utils/networkState';

const instances = {};
[AccountType.TRIP_COMPANY, AccountType.SYSTEM, AccountType.PUBLIC].forEach((accountType) => {
  instances[accountType] = axios.create({
    baseURL: `${process.env.REACT_APP_TRIP_COMPANY_API_SERVER_URL}/${process.env.REACT_APP_TRIP_COMPANY_API_SERVER_VERSION}/`.replace(/(?<!(http:|https:))\/\//g, '/'),
  });
  instances[accountType].defaults.headers.common['Content-Type'] = 'application/json';
  instances[accountType].defaults.headers.common.Accept = '*/*';
  // instances[accountType].defaults.headers.common.Accept = 'text/plain';
  instances[accountType].defaults.headers.common['x-service'] = accountType;
  instances[accountType].defaults.withCredentials = true;
});


// instance.interceptors.request.use((config, b, c) => {
//   console.log('INTERCEPT::', config, b, c);
//   // if (typeof config.headers['X-CSRF-TOKEN'] === 'undefined') {
//   //   // add X-CSRF-TOKEN header only if url begins with "/api/" or is on same domain
//   //   if (config.url.substring(0,5) === '/api/' || config.url.indexOf(window.location.hostname) !== -1) {
//   //     config.headers['X-CSRF-TOKEN'] = 'tok,tok,tok,ken est là?';
//   //   }
//   // }

//   return config;
// });

const useFetch = (setIsLoading = () => {}) => {
  const logger = new Logger({ name: 'useFetch', showDate: false, showFile: false });

  const callApi = async (api, apiParams) => {
    let params = apiParams instanceof FormData ? apiParams : Object.assign({}, apiParams);
    logger.debug('Fetch:', api);
    if (!api || typeof api !== 'object') {
      return { error: APICode.UNKNOWN_REQUEST };
    }

    const accountType = api.accountType || AccountType.TRIP_COMPANY;

    setIsLoading(true);

    let response = null;
    try {
      const config = { withCredentials: true, headers: { common: { 'x-service': AccountType.TRIP_COMPANY } } };
      // if (api.auth) {
      //   let authSession = getStorageValue(STORAGE.SESSION, null, User);
      //   if (!authSession || !authSession.account) {
      //     setIsLoading(false);
      //     return { error: APICode.UNAUTHORIZED }
      //   }
      // }

      let url = api.url;
      for (const param of api.uriVars) {
        let key = param.slice(1);
        const re = new RegExp(`${param}\\b`, 'gi');
        if (re.test(url)) {
          url = url.replace(re, params[key] || '');
          delete params[key];
        }
      }

      if (params instanceof FormData) {
        config.headers = { ...config.headers, 'Content-Type': 'multipart/form-data' };
        if (networkState.isAvailable()) {
          params.append('nocache', new Date().getTime());
        }
      } else if (networkState.isAvailable()) {
        params.nocache = new Date().getTime();
      }

      config.headers.common['x-service'] = api.accountType;

      response = await instances[accountType][api.method || 'post'](url, api.method === FetchMethod.GET ? {params} : params, config);
      setIsLoading(false);
    } catch (errorResponse) {
      console.log(`${process.env.REACT_APP_TRIP_COMPANY_API_SERVER_URL}/${process.env.REACT_APP_TRIP_COMPANY_API_SERVER_VERSION}/`, api.url);
      setIsLoading(false);

      if (errorResponse.response) {
        response = errorResponse.response;
        logger.error('Error:', errorResponse.config.url, errorResponse.config.data, response.data);
        return { error: response.data.error };
      } else if (errorResponse.code) {
        logger.error('Error:', errorResponse.config.url, errorResponse.config.data, errorResponse.code);
        return { error: errorResponse.code };
      } else {
        logger.error('Error:', errorResponse.config.url, errorResponse.config.data, APICode.UNKNOWN_RESPONSE);
        return { error: APICode.UNKNOWN_RESPONSE };
      }
    }

    logger.debug('Response:', response);
    if (response) {
      return response;
    } else {
      return { error: APICode.UNKNOWN_RESPONSE };
    }
  };

  return {
    callApi
  };
}

export default useFetch;