import DiscountBase from './DiscountBase';
import Excursion from '../excursion/Excursion';
import Schedule from '../schedule/Schedule';

class Discount extends DiscountBase {
  constructor({ excursion, schedule } = {}) {
    super(...arguments);
    this.excursion = this.ref(excursion, Excursion);
    this.schedule = this.ref(schedule, Schedule);
  }
}

export default Discount;