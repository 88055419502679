import ClassHelper from '../ClassHelper';

class Location extends ClassHelper {
  constructor({ type, coordinates = [] } = {}) {
    super(...arguments);
    this.type = type;
    this.coordinates = coordinates;
  }
}

export default Location;