import { RefundProvider } from '../../store/RefundProvider';
import RefundForm from './refund_form';

const NewOrder = () => {
  return (
    <RefundProvider>
      <RefundForm />
    </RefundProvider>
  )
};

export default NewOrder;