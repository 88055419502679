import ClassHelper from '../ClassHelper';

class PlaceDetails extends ClassHelper {
  constructor({ title, time, passengers: { expected = 0, actual = 0 } = {}} = {}) {
    super(...arguments);
    this.title = title;
    this.time = time;
    this.passengers = {
      expected,
      actual
    };
  }
}

export default PlaceDetails;