import { ClassTimestampsHelper } from '../ClassHelper';
import Account from '../account/Account';
import DynamicPrice from '../price/DynamicPrice';
import User from '../user/User';

class DiscountBase extends ClassTimestampsHelper {
  constructor({ type, title, account, cntActivations, startDate, endDate, discount, appliesTo, forGroups, users = [], status } = {}) {
    super(...arguments);
    this.type = type; // DiscountType
    this.title = title;
    this.account = this.ref(account, Account);
    this.cntActivations = cntActivations;
    this.startDate = this.obj(startDate, Date);
    this.endDate = this.obj(endDate, Date);
    this.discount = this.obj(discount, DynamicPrice);
    this.appliesTo = appliesTo; // AppliesTo
    this.forGroups = forGroups; // ForGroup
    this.users = this.array(users, User, true);
    this.status = status; // Status
  }
}

export default DiscountBase;