import { ClassTimestampsHelper } from '../ClassHelper';
import Account from '../account/Account';
import Excursion from '../excursion/Excursion';
import Passengers from './Passengers';
import Passport from './Passport';
import PaymentDetails from './PaymentDetails';
import Place from '../place/Place';
import Point from '../point/Point';
import Seat from './Seat';
import Seller from './Seller';
import Schedule from '../schedule/Schedule';
import Subdivision from '../subdivision/Subdivision';
import User from '../user/User';
import DiscountBase from '../discount/DiscountBase';

class Order extends ClassTimestampsHelper {
  constructor({ account, number, user, contactPhones = [], subdivision, point, excursion, schedule, date, wishes = [], passengers = [],
    place, address, seller, passports, status, paymentDetails, discount, seats = [], newSeats = [], comment
  } = {}) {
    super(...arguments);
    this.account = this.ref(account, Account);
    this.number = number;
    this.user = this.ref(user, User);
    this.contactPhones = this.array(contactPhones, String);
    this.subdivision = this.ref(subdivision, Subdivision);
    this.point = this.ref(point, Point);
    this.excursion = this.ref(excursion, Excursion);
    this.schedule = this.ref(schedule, Schedule);
    this.date = this.obj(date, Date);
    this.wishes = this.array(wishes, String); // Wishes
    this.passengers = this.array(passengers, Passengers);
    this.place = this.ref(place, Place);
    this.address = address;
    this.seller = this.obj(seller, Seller);
    this.passports = this.array(passports, Passport);
    this.status = status; // OrderStatus
    this.paymentDetails = this.obj(paymentDetails, PaymentDetails);
    this.discount = this.obj(discount, DiscountBase);
    this.seats = this.array(seats, Seat);
    this.newSeats = this.array(newSeats, Seat);
    this.comment = comment;
  }
}

export default Order;