import { ClassTimestampsHelper } from '../ClassHelper';
import Account from '../account/Account';
import Location from '../geo/Location';
import Subdivision from '../subdivision/Subdivision';

class Place extends ClassTimestampsHelper {
  constructor({ title, account, status, address, location, subdivision } = {}) {
    super(...arguments);
    this.title = title;
    this.account = this.ref(account, Account);
    this.status = status; // Status
    this.address = address;
    this.location = this.obj(location, Location);
    this.subdivision = this.ref(subdivision, Subdivision);
  }
}

export default Place;