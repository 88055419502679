import ClassHelper from '../ClassHelper';
import OrderStatExcursion from './OrderStatExcursion';
import OrderStatPrice from './OrderStatPrice';

class OrdersStat extends ClassHelper {
  constructor({ totalSeats, totalPeople, totalActualPeople, excursions, prices } = {}) {
    super(...arguments);
    this.totalSeats = totalSeats;
    this.totalPeople = totalPeople;
    this.totalActualPeople = totalActualPeople;
    this.excursions = this.obj(excursions, OrderStatExcursion);
    this.prices = this.map(prices, OrderStatPrice);
  }
}

export default OrdersStat;