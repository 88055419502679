import ClassHelper from '../ClassHelper';

class DynamicPrice extends ClassHelper {
  constructor({ value, type } = {}) {
    super(...arguments);
    this.value = value;
    this.type = type; // DynamicPriceType
  }
}

export default DynamicPrice;