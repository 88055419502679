import ClassHelper from '../ClassHelper';

class BusPlace extends ClassHelper {
  constructor({ type, number, userNumber, angle, isReserved } = {}) {
    super(...arguments);
    this.type = type; // BusPlaceType
    this.number = number;
    this.userNumber = userNumber;
    this.angle = angle;
    this.isReserved = isReserved;
  }
}

export default BusPlace;