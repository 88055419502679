import { ClassTimestampsHelper } from '../ClassHelper';
import Account from '../account/Account';
import Location from '../geo/Location';
import Subdivision from '../subdivision/Subdivision';
import Place from '../place/Place';

class Point extends ClassTimestampsHelper {
  constructor({ title, account, status, address, subdivision, location, openTime, closeTime, places = [] } = {}) {
    super(...arguments);
    this.account = this.ref(account, Account);
    this.subdivision = this.ref(subdivision, Subdivision);
    this.title = title;
    this.address = address;
    this.location = this.obj(location, Location);
    this.openTime = openTime;
    this.closeTime = closeTime;
    this.places = this.array(places, Place, true);
    this.status = status; // Status
  }
}

export default Point;