import { ClassTimestampsHelper } from '../ClassHelper';

class FreeService extends ClassTimestampsHelper {
  constructor({ title, status } = {}) {
    super(...arguments);
    this.title = title;
    this.status = status; // Status
  }
}

export default FreeService;