import ClassHelper from '../ClassHelper';
import Excursion from '../excursion/Excursion';
import Place from '../place/Place';
import Seat from '../order/Seat';

class PassengerDetails extends ClassHelper {
  constructor({ order, time, place, user: { phone, name } = {}, seats = [], passengers: { seats: passengerSeats, noSeats: passengerNoSeats } = {}, checked, excursion = {}} = {}) {
    super(...arguments);
    this.order = order; // Order ID
    this.time = time;
    this.place = this.ref(place, Place);
    this.user = {
      phone,
      name
    };
    this.seats = this.array(seats, Seat);
    this.passengers = {
      seats: passengerSeats,
      noSeats: passengerNoSeats
    };
    this.checked = !!checked;
    this.excursion = this.ref(excursion, Excursion);
  }
}

export default PassengerDetails;