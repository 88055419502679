import { useEffect, useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Alert from '../../components/Alert';
import { RepositoryContext } from '../../store/RepositoryProvider';
import Field from '../../components/Field';
import InputAutocomplete from '../../components/InputAutocomplete';
import API from '../../server/api';
import ReportParams from '../../models/report/ReportParams';
import Section from '../../components/Section';
import Info from '../../components/Info';
import Price, { CURRENCY } from '../../components/Price';
import { RefundContext } from '../../store/RefundProvider';

dayjs.extend(utc);
dayjs.extend(timezone);

const StepOrderInfo = ({ onComplete }) => {
  const {t} = useTranslation(['common', 'refund']);

  const repository = useContext(RepositoryContext);
  const refundInfo = useContext(RefundContext);

  const [orderList, setOrderList] = useState([]);
  const [orderSection, setOrderSection] = useState(true);
  const [order, setOrder] = useState(null);
  
  const [stepOrderId, setStepOrderId] = refundInfo.useState('orderId');
  const [stepOrderNumber, setStepOrderNumber] = refundInfo.useState('orderNumber');
  const [stepOrderExcursionName, setStepOrderExcursionName] = refundInfo.useState('orderTitle');
  const [stepOrderPaymentMethod, setStepOrderPaymentMethod] = refundInfo.useState('orderPaymentMethod');
  const [stepOrderStatus, setStepOrderStatus] = refundInfo.useState('orderStatus');
  const [stepOrderPassengers, setStepOrderPassengers] = refundInfo.useState('orderPassengers');
  const [stepOrderPoint, setStepOrderPoint] = refundInfo.useState('orderPoint');
  const [stepOrderAmount, setStepOrderAmount] = refundInfo.useState('orderAmount');
  const [stepOrderSeller, setStepOrderSeller] = refundInfo.useState('orderSeller');

  const [errors, setErrors] = useState([]);

  const handleCloseError = () => {
    setErrors([]);
  };

  const handleFilterReport = useCallback(() => {
    repository.report(API.order.orders, new ReportParams({ filters: { number: Number(stepOrderNumber) } })).then((report) => {
      setOrderList(report.rows.map((order) => order.number));

      if (report.rows.length === 1) {
        setOrder(report.rows[0]);

        setOrderSection(true);

        setStepOrderId(report.rows[0]._id);
        setStepOrderExcursionName(report.rows[0].excursion.title);
        setStepOrderPaymentMethod(report.rows[0].paymentMethod);
        setStepOrderStatus(report.rows[0].status);
        setStepOrderPassengers(report.rows[0].passengers);
        setStepOrderPoint(report.rows[0].point.title);
        setStepOrderAmount(report.rows[0].paidAmount);
        setStepOrderSeller(report.rows[0].seller.profile.fullName);
      } else {
        setOrder(null);

        setOrderSection(false);

        refundInfo.resetOrder();
      }
    }).catch(() => {
      setOrderList([]);
      setOrder(null);

      setOrderSection(false);

      refundInfo.resetOrder();
    });
  }, [stepOrderNumber]);

  useEffect(() => {
    handleFilterReport();
  }, [stepOrderNumber]);

  useEffect(() => {
  }, []);

  return (
    <>
      {errors.map((error, i) => (
        <Alert type={error.type} key={error.uid} title={error.title} dismissible={true} onClose={() => handleCloseError(error.uid)}>{error.message}</Alert>
      ))}

      <Field title={t('refund:step_order_info.select_order')}>
        <InputAutocomplete
          value={stepOrderNumber}
          list={orderList}
          placeholder={t('refund:step_order_info.order_number')}
          onInput={setStepOrderNumber}
        ></InputAutocomplete>
      </Field>

      <Section visible={orderSection} onVisible={setOrderSection}>
        <Field>
          <Info title={t('refund:step_order_info.status_order')}>
            {order && t(`common:payment_status.${stepOrderStatus}`)}
          </Info>
        </Field>

        <Field>
          <Info title={t('refund:step_order_info.trip')} id={stepOrderId}>
            {stepOrderExcursionName}
          </Info>
        </Field>

        <Field>
          <Info title={t('refund:step_order_info.payment_method')}>
            {order && t(`common:payment_method.${stepOrderPaymentMethod}`)}
          </Info>
        </Field>

        <Field>
          <Info title={t('refund:step_order_info.passengers')}>
            {(order && stepOrderPassengers instanceof Array) && (stepOrderPassengers.map((price) => (
              <span key={price.price._id} className='comma'>
                {price.price.title} {price.count}
              </span>
            )))}
          </Info>
        </Field>

        <Field>
          <Info title={t('refund:step_order_info.point')}>
            {order && (
              <>
                {stepOrderPoint}, {stepOrderSeller}
              </>
            )}
          </Info>
        </Field>

        <Field>
          <Info title={t('refund:step_order_info.amount')}>
            <Price currency={CURRENCY.RUB} value={order ? stepOrderAmount : 0} />
          </Info>
        </Field>
      </Section>
    </>
  )
}

StepOrderInfo.propTypes = {
  onChange: PropTypes.func,
  onComplete: PropTypes.func,
  onError: PropTypes.func,
};

export default StepOrderInfo;