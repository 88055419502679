import { ClassTimestampsHelper } from '../ClassHelper';
import Account from '../account/Account';
import RouteSubdivision from './RouteSubdivision';

class Route extends ClassTimestampsHelper {
  constructor({ title, account, status, subdivisions } = {}) {
    super(...arguments);
    this.title = title;
    this.account = this.ref(account, Account);
    this.subdivisions = this.array(subdivisions, RouteSubdivision);
    this.status = status; // Status
  }
}

export default Route;