import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Alert, { ALERT_ALIGN, ALERT_TYPE } from '../../components/Alert';
import { useNavigate } from 'react-router-dom';
import Field from '../../components/Field';
import Info from '../../components/Info';
import Price from '../../components/Price';
import InfoDescription from '../../components/InfoDescription';
import { RefundContext } from '../../store/RefundProvider';

const StepConfirmRefund = () => {
  const {t} = useTranslation(['common', 'refund']);
  const navigate = useNavigate();

  const refundInfo = useContext(RefundContext);

  const [stepOrderExcursion] = refundInfo.useState('orderTitle');
  const [stepRefundMethod] = refundInfo.useState('refundMethod');
  const [stepRefundAmount] = refundInfo.useState('refundAmount');
  const [stepRule] = refundInfo.useState('rule');
  const [stepUseRule] = refundInfo.useState('useRule');
  const [stepOrderAmount] = refundInfo.useState('orderAmount');
  const [stepReason] = refundInfo.useState('reason');
  
  const [errors, setErrors] = useState([]);


  const handleCloseError = (uid) => {
    setErrors([]);
  };

  return (
    <>
      {errors.map((error, i) => (
        <Alert type={error.type} key={error.uid} title={error.title} dismissible={true} onClose={() => handleCloseError(error.uid)}>{error.message}</Alert>
      ))}

      <Alert type={ALERT_TYPE.WARN} align={ALERT_ALIGN.LEFT} title={t('refund:step_confirm_refund.confirmation')} dismissible={false}>
        <p>{t('refund:step_confirm_refund.confirm_data')}</p>
        <p>{t('refund:step_confirm_refund.confirm_admin')}</p>
      </Alert>

      <Field>
        <Info title={t('refund:step_order_info.trip')}>
          {stepOrderExcursion}
        </Info>
      </Field>

      <Field>
        <Info title={t('refund:step_refund_info.refund_method')}>
          {t(`common:payment_method.${stepRefundMethod}`)}
        </Info>
      </Field>

      <Field>
        <Info title={t('refund:step_refund_info.refund_amount')}>
          {t('refund:step_confirm_refund.refund_from', {refund: Price.Text({value: stepRefundAmount}), total: Price.Text({value: stepOrderAmount})})}
          {stepUseRule && (
            <InfoDescription>
              {t('refund:step_refund_info.rule', stepRule)}
              {t('refund:step_refund_info.rule_description', {rule: stepRule.getValue(), value: Price.Text({value: stepRule.applyTo(stepOrderAmount)}), total: Price.Text({value: stepOrderAmount}) })}
            </InfoDescription>
          )}
        </Info>
      </Field>

      <Field>
        <Info title={t('refund:step_refund_info.reason')}>
          {stepReason}
        </Info>
      </Field>
    </>
  )
}

StepConfirmRefund.propTypes = {
  onChange: PropTypes.func,
  onComplete: PropTypes.func,
  onError: PropTypes.func,
};

export default StepConfirmRefund;