import ClassHelper from '../ClassHelper';
import OrderStatExcursionSubdivision from './OrderStatExcursionSubdivision';

class OrderStatExcursion extends ClassHelper {
  constructor({ excursion } = {}) {
    super(...arguments);
    this.excursion = this.obj(excursion, OrderStatExcursionSubdivision);
  }
}

export default OrderStatExcursion;