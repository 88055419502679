import ClassHelper from '../ClassHelper';
import Transport from '../transport/Transport';
import User from '../user/User';

class ScheduleTransport extends ClassHelper {
  constructor({ transport, guides = [], drivers = [], limit } = {}) {
    super(...arguments);
    this.transport = this.ref(transport, Transport);
    this.guides = this.array(guides, User, true);
    this.drivers = this.array(drivers, User, true);
    this.limit = limit;
  }
}

export default ScheduleTransport;