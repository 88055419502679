import { ClassTimestampsHelper } from '../ClassHelper';
import Account from '../account/Account';
import User from '../user/User';
import TransportConfig from './TransportConfig';

class Transport extends ClassTimestampsHelper {
  constructor({ title, account, number, config, drivers = [], status } = {}) {
    super(...arguments);
    this.title = title;
    this.account = this.ref(account, Account);
    this.number = number;
    this.drivers = this.array(drivers, User, true);
    this.config = this.obj(config, TransportConfig);
    this.status = status; // Status
  }
}

export default Transport;