import ClassHelper from '../ClassHelper';
import Account from '../account/Account';
import { Role } from '../types';

class UserAccount extends ClassHelper {
  constructor({ account, accountTitle, accountType, roles, status } = {}) {
    super(...arguments);
    this.account = this.ref(account, Account);
    this.accountTitle = accountTitle;
    this.accountType = accountType; // AccountType
    this.roles = this.array(roles, String); // Role
    this.status = status; // UserAccountStatus
  }

  isOwner() {
    return this.roles.indexOf(Role.OWNER) >= 0;
  }

  isAdmin() {
    return this.roles.indexOf(Role.ADMIN) >= 0;
  }

  isManager() {
    return this.roles.indexOf(Role.MANAGER) >= 0;
  }

  isAccountant() {
    return this.roles.indexOf(Role.ACCOUNTANT) >= 0;
  }

  isGuide() {
    return this.roles.indexOf(Role.GUIDE) >= 0;
  }

  isDriver() {
    return this.roles.indexOf(Role.DRIVER) >= 0;
  }

  isCashier() {
    return this.roles.indexOf(Role.CASHIER) >= 0;
  }

  isDispatcher() {
    return this.roles.indexOf(Role.DISPATCHER) >= 0;
  }

  isCollector() {
    return this.roles.indexOf(Role.COLLECTOR) >= 0;
  }
}

export default UserAccount;