import { ClassTimestampsHelper } from '../ClassHelper';
import Account from '../account/Account';
import Location from '../geo/Location';

class ObjectClass extends ClassTimestampsHelper {
  constructor({ account, inn, title, address, location, status } = {}) {
    super(...arguments);
    this.account = this.ref(account, Account);
    this.inn = inn;
    this.title = title;
    this.address = address;
    this.location = this.obj(location, Location);
    this.status = status; // ModerationStatus
  }
}

export default ObjectClass;