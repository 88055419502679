import { ClassTimestampsHelper } from '../ClassHelper';

class Price extends ClassTimestampsHelper {
  constructor({ title, status, priceType, noSeat, appliesTo } = {}) {
    super(...arguments);
    this.title = title;
    this.status = status; // Status
    this.priceType = priceType; // PriceType
    this.noSeat = noSeat;
    this.appliesTo = this.array(appliesTo, String); // PriceAppliesTo;
  }
}

export default Price;