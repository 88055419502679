import { useState, createContext, useContext, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from './UserProvider';
import useAPI from '../hooks/useAPI';
import API from '../server/api';
import { RepositoryContext } from './RepositoryProvider';
import { Step } from '../components/Stepper2';

export const RefundContext = createContext();

export const STEPS = {
  order_info: 0,
  refund_info: 1,
  confirm_refund: 2
};

const initialState = () => ({
  orderId: null,
  orderNumber: '',
  orderTitle: null,
  orderPaymentMethod: null,
  orderStatus: null,
  orderPassengers: [],
  orderPoint: null,
  orderAmount: 0,
  orderSeller: null,

  refundMethod: '',
  useRule: false,
  refundAmount: '',
  rule: null,
  reason: '',
  files: [],
});

const initSteps = (t) => {
  return Object.keys(STEPS).map((step) => new Step({ title: t(`refund:steps.${step}`), id: ~~(Math.random() * 10000) }));
};

export const RefundProvider = (props) => {
  const {t} = useTranslation(['common', 'refund']);

  const [page, setPage] = useState(0);
  const [refundInfo, setRefundInfo] = useState(initialState());
  const [steps] = useState(initSteps(t));

  const { callApi } = useAPI();

  const repository = useContext(RepositoryContext);

  const nextPage = () => {
    let newPage = page + 1;
    if (newPage <= steps.length && newPage >= 0) {
      steps[page].complete();
      if (newPage < steps.length) {
        steps[newPage].select();
        setPage(newPage);
      }
    }
  };

  const prevPage = () => {
    let newPage = page - 1;
    if (newPage < steps.length && newPage >= 0) {
      setPage(newPage);
    }
  };

  const setOrder = (order) => {
    setRefundInfo(order);
  };

  const getOrder = () => {
    return refundInfo;
  };

  const _setState = (field, value) => {
    // console.log('Set field:', field, value);
    setRefundInfo((prevState) => ({ ...prevState, [field]: value }));
  };

  const _useState = (field) => {
    return [refundInfo[field], (value) => _setState(field, value)];
  };

  const resetOrder = () => {
    setRefundInfo(initialState());
  };

  // useEffect(() => {
  //   console.log('Provider: orderNumber[useEffect[]]=', refundInfo.orderNumber);
  // }, []);

  // useEffect(() => {
  //   console.log('Provider: orderNumber[useEffect[...]]=', refundInfo.orderNumber);
  // }, [...Object.keys(initialState())]);

  return (
    <RefundContext.Provider
      value={{
        page,
        steps,
        nextPage,
        prevPage,
        setPage,
        setOrder,
        getOrder,
        useState: _useState,
        resetOrder
      }}
    >
      {props.children}
    </RefundContext.Provider>
  );
};
