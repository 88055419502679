import { ClassTimestampsHelper } from '../ClassHelper';
import Profile from './Profile';
import UserAccount from './UserAccount';

class User extends ClassTimestampsHelper {
  constructor({ profile, phone, salt = 'salt', language, email, password, accounts = [] } = {}) {
    super(...arguments);
    this.profile = this.obj(profile, Profile);
    this.phone = phone;
    this.email = email;
    this.password = password;
    this.salt = salt;
    this.language = language;
    this.accounts = this.array(accounts, UserAccount);
  }

  getAccount(accountId) {
    return this.accounts.find((a) => a.account.id === accountId) || null;
  }

  isValid() {
    return this.profile && !!this.profile.id;
  }
}

export default User;