import ClassHelper from '../ClassHelper';

class Report extends ClassHelper {
  constructor({ rows = [], totalRecords, page, totalPage, limit, sort = {}, filters = {}, accountId } = {}, clazz = null) {
    super(...arguments);
    this.rows = [];
    this.totalRecords = totalRecords;
    this.page = page;
    this.totalPage = totalPage;
    this.limit = limit;
    this.sort = sort;
    this.filters = filters;
    this.accountId = accountId;
    if (rows instanceof Array) {
      for (const row of rows) {
        this.rows.push(clazz && typeof clazz.constructor === 'function' ? new clazz(row || {}) : row);
      }
    }
  }

  size() {
    return this.rows.length;
  }
}

export default Report;