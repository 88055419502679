import ClassHelper from '../ClassHelper';
import Subdivision from '../subdivision/Subdivision';
import RoutePlace from './RoutePlace';

class RouteSubdivision extends ClassHelper {
  constructor({ subdivision, time, places = [] } = {}) {
    super(...arguments);
    this.subdivision = this.ref(subdivision, Subdivision);
    this.time = time;
    this.places = this.array(places, RoutePlace);
  }
}

export default RouteSubdivision;