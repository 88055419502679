import { ClassTimestampsHelper } from '../ClassHelper';
import Account from '../account/Account';
import Point from '../point/Point';
import User from '../user/User';
import DateFormat from '../../components/DateFormat';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

class EmployeeSchedule extends ClassTimestampsHelper {
  constructor({ account, user, point, date, timeFrom = 0, timeTo = 0 } = {}) {
    super(...arguments);
    this.account = this.ref(account, Account);
    this.user = this.ref(user, User);
    this.point = this.ref(point, Point);
    this.date = this.obj(date, Date);
    this.timeFrom = timeFrom;
    this.timeTo = timeTo;
  }

  getDate() {
    return DateFormat({ date: this.date });
  }

  getTimeFrom() {
    return `${(this.timeFrom / 100).toString().padStart(2, '0')}:${(this.timeFrom % 100).toString().padStart(2, '0')}`;
  }

  getTimeTo() {
    return `${(this.timeTo / 100).toString().padStart(2, '0')}:${(this.timeTo % 100).toString().padStart(2, '0')}`;
  }

  getSchedule() {
    return {
      date: dayjs(this.date),
      from: this.timeFrom,
      to: this.timeTo
    };
  }
}

export default EmployeeSchedule;