import ClassHelper from '../ClassHelper';
import { CollectionType } from '../types';
import User from '../user/User';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

class CashboxDetails extends ClassHelper {
  constructor({
    total: { totalCash: cash = 0, totalSalary: salary = 0 } = {},
    stat: { totalAmount = 0, totalCash = 0, totalOtherAmount = 0, totalRefunds = 0, totalRefundCash = 0, totalOtherRefunds = 0, collectedAmount = 0, totalSalary = 0, salaryWithdrawn = 0, totalOrders = 0, totalCanceledOrders = 0 } = {},
    history = []
  } = {}) {
    super(...arguments);
    this.total = {
      totalCash: cash,
      totalSalary: salary
    };
    this.stat = {
      totalAmount,
      totalCash,
      totalOtherAmount,
      totalRefunds,
      totalRefundCash,
      totalOtherRefunds,
      collectedAmount,
      totalSalary,
      salaryWithdrawn,
      totalOrders,
      totalCanceledOrders
    };

    this.history = [];
    if (history instanceof Array) {
      history.forEach((h) => {
        this.addHistory(h);
      });
    }
  }

  addHistory(h) {
    let date = this.obj(h.date, Date);
    let day = dayjs(date);

    let history = {
      date,
      amount: h.amount,
      type: h.type, //CollectionType
      day: day.format('DD.MM.YYYY'),
      time: day.format('HH:mm'),
    };
    if (h.type === CollectionType.COLLECTION) {
      history.collector = this.obj(h.collector, User);
    }

    this.history.push(history);
  }
}

export default CashboxDetails;