import { ClassTimestampsHelper } from '../ClassHelper';
import Account from '../account/Account';

class Subdivision extends ClassTimestampsHelper {
  constructor({ account, title, status } = {}) {
    super(...arguments);
    this.account = this.ref(account, Account);
    this.title = title;
    this.status = status; // Status
  }
}

export default Subdivision;