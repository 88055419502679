import ClassHelper from '../ClassHelper';

class OrderStatExcursionSubdivision extends ClassHelper {
  constructor({ count, actualCount } = {}) {
    super(...arguments);
    this.count = count;
    this.actualCount = actualCount;
  }
}

export default OrderStatExcursionSubdivision;