import ClassHelper from '../ClassHelper';

class ExcursionFaq extends ClassHelper {
  constructor({ whatToBring, whatToExpect, suitableFor } = {}) {
    super(...arguments);
    this.whatToBring = whatToBring;
    this.whatToExpect = whatToExpect;
    this.suitableFor = suitableFor;
  }
}

export default ExcursionFaq;