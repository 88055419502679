import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../server/api';
import useAPI from '../../hooks/useAPI';
import InputWithError from '../../components/InputWithError';
import { ROUTES } from '../../defs/routes';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../store/UserProvider';
import { RepositoryContext } from '../../store/RepositoryProvider';
import Phone from '../../components/Phone';
import Button, { BUTTON_POSITION } from '../../components/Button';
import Row from '../../components/Row';

const LoginForm = ({ onSubmit }) => {
  const navigate = useNavigate();

  const {t} = useTranslation(['common', 'auth']);

  const userInfo = useContext(UserContext);
  const repository = useContext(RepositoryContext);

  const [phone, setPhone] = useState({ value: '', error: false });
  const [password, setPassword] = useState({ value: '', error: false });

  const { isLoading } = useAPI();

  const signIn = () => {
    return userInfo.signIn(phone.value, password.value).then((response) => {
      if (repository.isServerUnavailableIssue()) {
        setPhone({ value: phone.value, error: false });
      } else if (response.error) {
        setPhone(response);
      } else {
        navigate(ROUTES.INDEX, { replace: true });
      }
      onSubmit && onSubmit();
    }).catch((e) => {
      setPhone(e);
      onSubmit && onSubmit();
    });
  };

  const handleSubmit = () => {
    if (API.auth.signIn.validate({ phone: phone.value, password: password.value }).result) {
      if (userInfo.isLoggedIn()) {
        userInfo.signOut().then(signIn).catch(signIn);
      } else {
        signIn();
      }
    } else {
      if (!phone.value) {
        setPhone({ value: phone.value, error: t('auth:error.empty_phone') });
      }
      if (!password.value) {
        setPassword({ value: password.value, error: t('auth:error.empty_password') });
      }
    }
  }

  return (
    <form>
      <Row>
        <Phone.Input id="phone" name="phone" className="text form-control" placeholder={t('auth:form.phone_placeholder')} label={t('auth:form.phone')}
          value={phone.value}
          error={phone.error}
          onChange={(val) => {
            setPhone({
              value: val,
              error: !val,
            });
          }}
          onEnter={handleSubmit}
        />
      </Row>

      <Row>
        <InputWithError type="password" name="password" placeholder={t('auth:form.password_placeholder')} label={t('auth:form.password')}
          value={password.value}
          error={password.error}
          onChange={(val) => {
            setPassword({
              value: val,
              error: !val,
            });
          }}
          onEnter={handleSubmit}
        />
      </Row>

      <Row>
        <Button.Panel>
          <Button disabled={isLoading} position={BUTTON_POSITION.LEFT} onClick={handleSubmit}>{t('auth:button.entry')}</Button>
        </Button.Panel>
      </Row>
    </form>
  )
}

export default LoginForm;