import ClassHelper from '../ClassHelper';
import Limit from './Limit';
import Subdivision from '../subdivision/Subdivision';
import Transport from '../transport/Transport';
import ExcursionPrice from '../excursion/ExcursionPrice';

class ScheduleSubdivision extends ClassHelper {
  constructor({ subdivision, limit, transports = [], prices = [] } = {}) {
    super(...arguments);
    this.subdivision = this.ref(subdivision, Subdivision);
    this.limit = this.obj(limit, Limit);
    this.transports = this.array(transports, Transport, true);
    this.prices = this.array(prices, ExcursionPrice, true);
  }
}

export default ScheduleSubdivision;