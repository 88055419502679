import { useState, createContext, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../server/api';
import Logger from '../utils/logger';
import { STORAGE } from '../defs/storage';
import { getStorageValue, setStorageValue } from '../hooks/useLocalStorage';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Point from '../models/point/Point';
import Schedule from '../models/schedule/Schedule';
import User from '../models/user/User';
import UserSession from '../models/session/UserSession';
import Place from '../models/place/Place';
import { RepositoryContext } from './RepositoryProvider';
import OrderSession from '../models/session/OrderSession';
import ClientDetails from '../models/user/ClientDetails';
import Order from '../models/order/Order';

export const UserContext = createContext();

dayjs.extend(utc);
dayjs.extend(timezone);

const initialState = {
  session: getStorageValue(STORAGE.SESSION, null, UserSession),
  orderSession: getStorageValue(STORAGE.ORDER_SESSION, null, OrderSession),
  user: null,
  scheduleDates: [],
  scheduleTimes: [],
  schedules: [],
  points: [],
  places: [],
  prices: [],
};

export const UserProvider = (props) => {
  const logger = new Logger({ name: 'UserProvider', showDate: false, showFile: false });
  const {t} = useTranslation(['common', 'account']);

  const [userInfo, setUserInfo] = useState(initialState);

  const repository = useContext(RepositoryContext);

  const fetch = {
    user: () => {
      return repository.action(API.user.whoami).then((response) => {
        let user = null;
        if (response.error) {
          //TODO:: handle error
        } else {
          user = setUser(response);
          if (user) {
            let userSession = getSession();
            let oldAccountSession = { ...userSession };
            let newAccount = userSession.account;

            if (user.accounts.length) {
              let account = null;
              if (userSession.account) {
                account = user.accounts.find((account) => account.account === userSession.account);
              }
              if (!account) {
                account = user.accounts[0];
              }
              newAccount = !account || !account.account || newAccount !== account.account.id;

              setSessionAccount((account && account.account && account.account.id) || null);
              if (account && account.account && oldAccountSession.account === account.account.id && account.roles.indexOf(oldAccountSession.role) >= 0) {
                setSessionRole(oldAccountSession.role);
              } else {
                setSessionRole((account && account.roles && account.roles[0]) || null);
              }
            } else {
              newAccount = true;
              setSessionAccount(null);
              setSessionRole(null);
            }

            if (newAccount) {
              setSessionPoint(null);
              setSessionSubdivision(null);
              loadPoints().catch(() => {});
            } else {
              setSessionPoint(userSession.point);
              setSessionSubdivision(userSession.subdivision);
            }
          } else {
            setSessionAccount(null);
            setSessionRole(null);
            setSessionPoint(null);
            setSessionSubdivision(null);
          }
        }
        return user;
      }).catch((e) => {
        logger.error(e);
        return Promise.reject(e);
      });
    },

    points: () => {
      return repository.report(API.point.report, { accountId: getSession().account, id: getSession().subdivision }, Point).then((report) => report.rows);
    },

    schedules: (day) => {
      return new Promise((resolve, reject) => {
        let date = dayjs().format('YYYY-MM-DD');
        if (day) {
          date = dayjs(day).format('YYYY-MM-DD');
        }

        let subdivision = getSession().subdivision;

        if (subdivision) {
          repository.action(API.excursion.excursions, {date: date, timezone: dayjs.tz.guess(), subdivision}).then((response) => {
            resolve(setSchedules(response));
          }).catch(reject);
        } else {
          resolve([]);
        }
      });
    },

    scheduleDates: (excursionId, subdivisionId) => {
      return repository.action(API.schedule.datesSeats, { accountId: getSession().account, id: excursionId, subdivision: subdivisionId }, String).then((dates) => {
        setScheduleDates(dates);
        return dates;
      });
    },

    scheduleTimes: (excursionId, day) => {
      return repository.list(API.schedule.times, { id: excursionId, date: dayjs(day || undefined).format('YYYY-MM-DD'), accountId: getSession().account }, Object).then((times) => {
        setScheduleTimes(times);
        return times;
      });
    },

    place: (id) => {
      return repository.action(API.place.place, {id}).then((response) => {
        let place = new Place(response);
        setPlaces([ ...userInfo.places, place ]);
        return place;
      });
    }
  };

  useEffect(() => {
    getPoints();
  }, [userInfo.session.account]);

  const reset = () => {
    setUserInfo(initialState);
  };


  const setSession = (prop = null) => {
    let session = prop === null ? null : prop instanceof UserSession ? prop : new UserSession({ ...userInfo.session, ...prop });
    setStorageValue(STORAGE.SESSION, session);
    setUserInfo((prevState) => { prevState.session = session; return prevState; });
  };

  const setSessionAccount = (prop = null) => {
    if (prop !== userInfo.session.account) {
      userInfo.session.account = prop || null;
      userInfo.session.point = null;
      userInfo.session.role = null;
      userInfo.session.subdivision = null;
      setStorageValue(STORAGE.SESSION, userInfo.session);
    }
  };

  const setSessionRole = (prop = null) => {
    userInfo.session.role = prop || null;
    setStorageValue(STORAGE.SESSION, userInfo.session);
  };

  const setSessionPoint = (prop = null) => {
    userInfo.session.point = prop || null;
    setStorageValue(STORAGE.SESSION, userInfo.session);
  };

  const setSessionSubdivision = (prop = null) => {
    userInfo.session.subdivision = prop || null;
    setStorageValue(STORAGE.SESSION, userInfo.session);
  };

  const setLoggedIn = (logged_in) => {
    userInfo.session.logged_in = !!logged_in;
    setStorageValue(STORAGE.SESSION, userInfo.session);
  };

  const getSession = () => {
    let session = getStorageValue(STORAGE.SESSION, null, UserSession);
    userInfo.session.account = session.account;
    userInfo.session.role = session.role;
    userInfo.session.point = session.point;
    userInfo.session.subdivision = session.subdivision;
    userInfo.session.logged_in = session.logged_in;

    return userInfo.session;
  };

  const isLoggedIn = () => {
    let session = getSession();
    return session.logged_in;
  };

  const resetSession = () => {
    let userSession = new UserSession();
    setUserInfo((prevState) => { prevState.session = userSession; return prevState; });
    setStorageValue(STORAGE.SESSION, userSession);
  };


  const setOrderSession = (prop = null) => {
    let orderSession = prop === null ? null : prop instanceof OrderSession ? prop : new OrderSession({ ...userInfo.orderSession, ...prop });
    setStorageValue(STORAGE.ORDER_SESSION, orderSession);
    setUserInfo((prevState) => { prevState.orderSession = orderSession; return prevState; });
  };

  const getOrderSession = () => {
    userInfo.orderSession = getStorageValue(STORAGE.ORDER_SESSION, null, OrderSession);
    return userInfo.orderSession;
  };

  const resetOrderSession = () => {
    let orderSession = new OrderSession();
    userInfo.orderSession = orderSession;
    setUserInfo((prevState) => { prevState.orderSession = orderSession; return prevState; });
    setStorageValue(STORAGE.ORDER_SESSION, orderSession);
  };


  const setUser = (prop = null) => {
    let user = prop === null ? null : prop instanceof User ? prop : new User({ ...userInfo.user, ...prop });
    setUserInfo((prevState) => { prevState.user = user; return prevState; });
    return user;
  };

  const getUser = () => {
    return new Promise((resolve, reject) => {
      let user = userInfo.user;
      if ((user === null || !user.isValid()) && isLoggedIn()) {
        loadUser().then(resolve).catch(reject);
      } else if (isLoggedIn()) {
        resolve(user);
      } else {
        reject();
      }
    });
  };

  const loadUser = () => {
    return fetch.user().then((user) => {
      setUser(user);
      return loadPoints().then(() => {
        return user;
      }).catch((e) => {
        return user;
      });
      // return user;
    });
  };

  const loadPoints = () => {
    return new Promise((resolve, reject) => {
      if (getSession().account) {
        fetch.points().then((points) => {
          if (points) {
            setPoints(points);
            let _point = points.find((p) => p.id === getSession().point);
            if (!getSession().point || !_point) {
              setSessionPoint(points.length ? points[0].id : null);
              setSessionSubdivision(points.length ? points[0].subdivision.id : null);
            }
            resolve(points);
          } else {
            resolve([]);
          }
        }).catch((e) => {
          logger.error('Load Points:', e);
          reject([]);
        });
      } else {
        reject([]);
      }
    });
  };


  const setSchedules = (prop = []) => {
    let schedules = prop === null ? [] : prop.map((schedule) => new Schedule(schedule));
    setUserInfo((prevState) => { prevState.schedules = schedules; return prevState; });
    return schedules;
  };

  const getSchedules = () => {
    return userInfo.schedules;
  };


  const setScheduleDates = (prop = []) => {
    let scheduleDates = prop === null ? [] : prop.map((scheduleDates) => new Schedule(scheduleDates));
    setUserInfo((prevState) => { prevState.scheduleDates = scheduleDates; return prevState; });
    return scheduleDates;
  };

  const getScheduleDates = async (day) => {
    return userInfo.scheduleDates;
  };


  const setScheduleTimes = (prop = []) => {
    let scheduleTimes = prop === null ? [] : prop.map((schedulTimee) => new Schedule(schedulTimee));
    setUserInfo((prevState) => { prevState.scheduleTimes = scheduleTimes; return prevState; });
    return scheduleTimes;
  };

  const getScheduleTimes = async (day) => {
    return userInfo.scheduleTimes;
  };


  const setPoints = (prop = []) => {
    let points = prop === null ? [] : prop.map((point) => new Point(point))
    setUserInfo((prevState) => { prevState.points = points; return prevState; });
    return points;
  };

  const getPoints = () => {
    return userInfo.points;
  };


  const setPlaces = (prop = []) => {
    let places = prop === null ? [] : prop.map((place) => new Place(place))
    setUserInfo((prevState) => { prevState.places = places; return prevState; });
    return places;
  };


  const getMyOrders = async (params) => {
    return repository.list(API.cashier.orders, params, Order).then((rows) => {
      return rows || [];
    });
  };

  const autocompleteOrderClients = async (query) => {
    return repository.list(API.order.autocomplete, { query }, ClientDetails).then((clients) => {
      return clients || [];
    });
  };

  const signIn = (phone, password) => {
    return new Promise((resolve, reject) => {
      repository.action(API.auth.signIn, { phone, password }).then((response) => {
        if (response) {
          if (response.error) {
            reject({ value: phone, error: t(`common:error.${response.error.toLowerCase()}`) });
          } else {
            setLoggedIn(true);
            getUser().then((user) => {
              if (user && user.isValid()) {
                resolve({ value: phone, error: false });
              } else {
                reject({ value: phone, error: t('common:error.user_not_found')});
              }
            }).catch((e) => {
              reject({ value: phone, error: t('common:error.user_not_found')});
            });
          }
        } else {
          reject({ value: phone, error: t('common:error.unknown_response')});
        }
      }).catch((e) => {
        reject({ value: phone, error: t(`common:error.${e.message.toLowerCase()}`) });
      });
    });
  };


  const signOut = () => {
    setLoggedIn(false);
    resetSession();
    setUser(null);
    resetOrderSession();
    return repository.action(API.auth.signOut);
  };


  return (
    <UserContext.Provider
      value={{
        reset,

        setSession,
        setSessionAccount,
        setSessionPoint,
        setSessionRole,
        setSessionSubdivision,
        setLoggedIn,
        getSession,
        isLoggedIn,
        resetSession,

        setOrderSession,
        getOrderSession,
        resetOrderSession,

        setUser,
        getUser,
        setSchedules,
        getSchedules,
        setScheduleDates,
        getScheduleDates,
        setScheduleTimes,
        getScheduleTimes,
        setPoints,
        getPoints,
        getMyOrders,
        autocompleteOrderClients,

        loadUser,
        loadPoints,

        signIn,
        signOut,
        fetch
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
