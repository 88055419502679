import { useCallback, useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ROUTES } from '../../defs/routes';
import StepCommonInfo from './step_common_info';
import Stepper from '../../components/Stepper';
import BackBar from '../../components/BackBar';
import Button, { BUTTON_POSITION, BUTTON_TYPE } from '../../components/Button';
import Modal from '../../components/Modal';
import { OrderContext, STEPS } from '../../store/OrderProvider';
import { UserContext } from '../../store/UserProvider';
import StepClientInfo from './step_client_info';
import StepPaymentBooking from './step_paymet_booking';
import ConfirmOrder from './confirm_order';
import Payment from './payment';
import Price, { CURRENCY } from '../../components/Price';
import InfoDescription from '../../components/InfoDescription';
import log from '../../utils/logger';
import StepPrint from './step_print';

const OrderForm = () => {
  let navigate = useNavigate();

  const {t} = useTranslation(['common', 'order']);

  const orderInfo = useContext(OrderContext);
  const userInfo = useContext(UserContext);

  const handleCompleteStepCommonInfo = useCallback(() => {
    orderInfo.setCompleteStep(STEPS.common_info);
  }, []);

  const handleCompleteStepClientInfo = useCallback(() => {
    orderInfo.setCompleteStep(STEPS.client_info);
  }, []);

  const handleCompleteStepPaymentBooking = useCallback(() => {
    orderInfo.setCompleteStep(STEPS.payment_booking);
  }, []);

  const handleCompleteStepPrint = useCallback(() => {
    orderInfo.setCompleteStep(STEPS.print);
  }, []);

  const handleNextStep = useCallback(async () => {
    let errors = await orderInfo.validate(orderInfo.page);
    let isValid = errors.length === 0;
    if (isValid) {
      if (orderInfo.page === STEPS.client_info) {
        handleConfirmOrderShow();
      } else if (orderInfo.page === STEPS.payment_booking) {
        orderInfo.createOrder().then((order) => {
          console.log(order);
          userInfo.setOrderSession({order_id: order.id});
          handlePaymentShow();
        }).catch((e) => {
          log.error(e);
          orderInfo.addError(STEPS.payment_booking, e.error, e.error, e.error);
        });
      } else {
        orderInfo.setCompleteStep(orderInfo.page);
        orderInfo.nextPage();
      }
    }
  }, [orderInfo.page]);

  const handleConfirmOrder = useCallback(async () => {
    // let isNewPhone = await orderInfo.isNewPhone();
    // if (isNewPhone) {
    //   let user = await orderInfo.inviteUser();
    //   if (user) {
    //     orderInfo.setClientInfo({ user: user.id });
    //     userInfo.setOrderSession({ user_id: user.id });
    //   }
    // } else {
    //   let userAccount = await orderInfo.getUserAccount();
    //   if (!userAccount) {
    //     let user = await orderInfo.inviteUser();
    //     if (user) {
    //       orderInfo.setClientInfo({ user: user.id });
    //       userInfo.setOrderSession({ user_id: user.id });
    //     }
    //   } else {
    //     let user = await orderInfo.askUser(userAccount.account.id);
    //     if (user) {
    //       orderInfo.setClientInfo({ user: user.id });
    //       userInfo.setOrderSession({ user_id: user.id });
    //     }
    //   }
    // }

    orderInfo.nextPage();
    setShowConfirmOrder(false);
  }, [orderInfo.page]);

  // const handlePaymentSuccess = useCallback(async () => {
  //   setShowPayment(false);
  // }, [orderInfo.page]);

  const handleCancel = useCallback(() => {
    orderInfo.reset();
    navigate(ROUTES.EXCURSIONS, { replace: true });
  }, []);

  const handleSelectStep = useCallback((idx) => {
    orderInfo.selectPage(idx);
  }, []);

  const [showConfirmOrder, setShowConfirmOrder] = useState(false);
  const handleConfirmOrderClose = () => setShowConfirmOrder(false);
  const handleConfirmOrderShow = () => setShowConfirmOrder(true);

  const [showPayment, setShowPayment] = useState(false);
  const handlePaymentClose = () => setShowPayment(false);
  const handlePaymentShow = () => setShowPayment(true);

  useEffect(() => {
    console.log('--------COMMON', orderInfo.getCommonInfo());
    console.log('--------CLIENT', orderInfo.getClientInfo());
    console.log('--------PAYMENT', orderInfo.getPaymentBooking());
    console.log('--------ORDER', userInfo.getOrderSession());
    console.log('--------SESSION', userInfo.getSession());

    log.print('order', orderInfo.getCommonInfo());
  }, []);

  return (
    <>
      <div className="trip-app">
        <BackBar title={t('order:new_order.title')}>
          <Stepper steps={orderInfo.steps} activeStep={orderInfo.page} onSelect={handleSelectStep}></Stepper>
        </BackBar>
        <div className="trip-app-body">
          <table>
            <tbody>
              <tr>
                <td>
                  <InfoDescription description={t('order:new_order.in_check')}>
                    {orderInfo.page === STEPS.common_info && (<Price currency={CURRENCY.RUB} value={orderInfo.getCommonInfoAmount()} />)}
                    {orderInfo.page === STEPS.client_info && (<Price currency={CURRENCY.RUB} value={orderInfo.getClientInfoAmount()} />)}
                    {orderInfo.page === STEPS.payment_booking && (<Price currency={CURRENCY.RUB} value={orderInfo.getPaymentBookingAmount()} />)}
                  </InfoDescription>
                </td>
                <td>
                  <InfoDescription description={t('order:new_order.total')}>
                    <Price currency={CURRENCY.RUB} value={orderInfo.getTotalAmount()} />
                  </InfoDescription>
                </td>
                <td>
                  <InfoDescription description={t('order:new_order.seats')}>
                    -
                  </InfoDescription>
                </td>
              </tr>
            </tbody>
          </table>
          <hr/>
          {orderInfo.page === STEPS.common_info && <StepCommonInfo onComplete={handleCompleteStepCommonInfo}/>}
          {orderInfo.page === STEPS.client_info && <StepClientInfo onComplete={handleCompleteStepClientInfo}/>}
          {orderInfo.page === STEPS.payment_booking && <StepPaymentBooking onComplete={handleCompleteStepPaymentBooking}/>}
          {orderInfo.page === STEPS.print && <StepPrint onComplete={handleCompleteStepPrint}/>}
        </div>
        <div className="trip-app-footer">
          <Button.Panel>
            <Button position={BUTTON_POSITION.LEFT} variant={BUTTON_TYPE.PRIMARY} onClick={handleNextStep}>{t('common:button.next')}</Button>
            <Button position={BUTTON_POSITION.RIGHT} variant={BUTTON_TYPE.SECONDARY} onClick={handleCancel}>{t('common:button.cancel')}</Button>
          </Button.Panel>
        </div>
      </div>

      <Modal show={showConfirmOrder} onHide={handleConfirmOrderClose}>
        <Modal.Header>{t('order:confirm_order.title')}</Modal.Header>
        <Modal.Body>
          <ConfirmOrder/>
        </Modal.Body>
        <Modal.Footer>
          <Button.Panel>
            <Button position={BUTTON_POSITION.LEFT} variant={BUTTON_TYPE.PRIMARY} onClick={handleConfirmOrder}>{t('common:button.alright')}</Button>
            <Button position={BUTTON_POSITION.RIGHT} variant={BUTTON_TYPE.SECONDARY} onClick={handleConfirmOrderClose}>{t('common:button.cancel')}</Button>
          </Button.Panel>
        </Modal.Footer>
      </Modal>

      <Modal show={showPayment} onHide={handlePaymentClose}>
        <Modal.Header>{t('order:payment.title')}. {t(`order:payment_booking.${userInfo.getOrderSession().payment_method}`)}</Modal.Header>
        <Modal.Body>
          <Payment type={userInfo.getOrderSession().payment_method} onCancel={handlePaymentClose} onSuccess={handlePaymentClose}/>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button.Panel>
            <Button position={BUTTON_POSITION.LEFT} variant={BUTTON_TYPE.PRIMARY} onClick={handlePayment}>{t('common:button.paid')}</Button>
            <Button position={BUTTON_POSITION.RIGHT} variant={BUTTON_TYPE.SECONDARY} onClick={handlePaymentClose}>{t('common:button.cancel')}</Button>
          </Button.Panel> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

OrderForm.propTypes = {
  onNext: PropTypes.func,
  onClose: PropTypes.func,
};

export default OrderForm;