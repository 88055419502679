import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ROUTES } from '../../defs/routes';
import BackBar from '../../components/BackBar';
import Button, { BUTTON_POSITION, BUTTON_TYPE } from '../../components/Button';
import { RefundContext, STEPS } from '../../store/RefundProvider';
import StepOrderInfo from './step_order_info';
import Stepper2 from '../../components/Stepper2';
import StepRefundInfo from './step_refund_info';
import StepConfirmRefund from './step_confirm_refund';

const RefundForm = () => {
  const {t} = useTranslation(['common', 'refund']);
  let navigate = useNavigate();

  const refundInfo = useContext(RefundContext);

  const handleSelectStep = useCallback((page) => {
    refundInfo.setPage(page);
  }, [refundInfo.page]);

  const handleNextStep = useCallback(() => {
    if (refundInfo.page === STEPS.order_info) {
      if (refundInfo.getOrder()) {
        refundInfo.nextPage();
      }
    } else if (refundInfo.page === STEPS.refund_info) {
      refundInfo.nextPage();
    }
  }, [refundInfo.page]);

  const handleCancel = useCallback(() => {
    navigate(ROUTES.REFUNDS, { replace: true });
  }, []);

  const handleConfirm = useCallback(() => {}, []);

  const handleCompleteStepOrderInfo = () => {};
  const handleCompleteStepRefundInfo = () => {};
  const handleCompleteStepConfirmRefund = () => {};

  return (
    <>
      <div className="trip-app">
        <BackBar title={t('refund:new_refund.title')}>
          <Stepper2 steps={refundInfo.steps} activeStep={refundInfo.page} onSelect={handleSelectStep}></Stepper2>
        </BackBar>
        <div className="trip-app-body">
          {refundInfo.page === STEPS.order_info && <StepOrderInfo onComplete={handleCompleteStepOrderInfo}/>}
          {refundInfo.page === STEPS.refund_info && <StepRefundInfo onComplete={handleCompleteStepRefundInfo}/>}
          {refundInfo.page === STEPS.confirm_refund && <StepConfirmRefund onComplete={handleCompleteStepConfirmRefund}/>}
        </div>
        <div className="trip-app-footer">
          <Button.Panel>
            {refundInfo.page === STEPS.confirm_refund && (
              <Button position={BUTTON_POSITION.LEFT} variant={BUTTON_TYPE.PRIMARY} onClick={handleConfirm}>{t('common:button.confirm')}</Button>
            )}
            {refundInfo.page !== STEPS.confirm_refund && (
              <Button position={BUTTON_POSITION.LEFT} variant={BUTTON_TYPE.PRIMARY} onClick={handleNextStep}>{t('common:button.next')}</Button>
            )}
          <Button position={BUTTON_POSITION.RIGHT} variant={BUTTON_TYPE.SECONDARY} onClick={handleCancel}>{t('common:button.cancel')}</Button>
          </Button.Panel>
        </div>
      </div>

    </>
  );
};

RefundForm.propTypes = {
  onNext: PropTypes.func,
  onClose: PropTypes.func,
};

export default RefundForm;