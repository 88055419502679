import ClassHelper from '../ClassHelper';

class Passport extends ClassHelper {
  constructor({ number, fullName } = {}) {
    super(...arguments);
    this.number = number;
    this.fullName = fullName;
  }
}

export default Passport;