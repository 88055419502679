import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ROUTES } from '../defs/routes';
import Index from '../screen/index';
import Login from '../screen/auth/login';
import Accounts from '../screen/account/accounts';
import Profile from '../screen/user/profile';
import Cashbox from '../screen/cashbox/cashbox';
import Salary from '../screen/salary/salary';
import Schedule from '../screen/schedule/schedule';
import MyRoutes from '../screen/route/my_routes';
import ArchiveRoutes from '../screen/route/archive_routes';
import Boarding from '../screen/place/boarding';
import Excursions from '../screen/excursion/excursions';
import Collections from '../screen/collection/collections';
import MySales from '../screen/sale/my_sales';
import NewOrder from '../screen/order/new_order';
import UIExample from '../screen/dev/ui_example';
import Tickets from '../screen/user/tickets';
import Refunds from '../screen/refund/refunds';
import NewRefund from '../screen/refund/new_refund';
import PrivatePage from './PrivatePage';
import { Role } from '../models/types';


const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={<Index />} />
          <Route path={ROUTES.AUTH} element={<Login />} />
          <Route path={ROUTES.ACCOUNTS} element={<PrivatePage><Accounts /></PrivatePage>} />
          <Route path={ROUTES.CASHBOX} element={<PrivatePage roles={[Role.CASHIER]}><Cashbox /></PrivatePage>} />
          <Route path={ROUTES.COLLECTION_HISTORY} element={<PrivatePage roles={[Role.COLLECTOR]}><Collections /></PrivatePage>} />
          <Route path={ROUTES.EXCURSIONS} element={<PrivatePage roles={[Role.CASHIER]}><Excursions /></PrivatePage>} />
          <Route path={ROUTES.NEW_ORDER} element={<PrivatePage roles={[Role.CASHIER]}><NewOrder /></PrivatePage>} />
          <Route path={ROUTES.BOARDING} element={<PrivatePage roles={[Role.CASHIER]}><Boarding /></PrivatePage>} />
          <Route path={ROUTES.ARCHIVE_ROUTES} element={<PrivatePage roles={[Role.GUIDE]}><ArchiveRoutes /></PrivatePage>} />
          <Route path={ROUTES.MY_ROUTES} element={<PrivatePage roles={[Role.GUIDE]}><MyRoutes /></PrivatePage>} />
          <Route path={ROUTES.MY_SALES} element={<PrivatePage roles={[Role.CASHIER]}><MySales /></PrivatePage>} />
          <Route path={ROUTES.SCHEDULE} element={<PrivatePage roles={[Role.CASHIER]}><Schedule /></PrivatePage>} />
          <Route path={ROUTES.PROFILE} element={<PrivatePage><Profile /></PrivatePage>} />
          <Route path={ROUTES.SALARY} element={<PrivatePage roles={[Role.CASHIER]}><Salary /></PrivatePage>} />
          <Route path={ROUTES.TICKETS} element={<PrivatePage roles={[Role.CASHIER]}><Tickets /></PrivatePage>} />
          <Route path={ROUTES.REFUNDS} element={<PrivatePage roles={[Role.CASHIER]}><Refunds /></PrivatePage>} />
          <Route path={ROUTES.NEW_REFUND} element={<PrivatePage roles={[Role.CASHIER]}><NewRefund /></PrivatePage>} />

          <Route path={ROUTES.UI_EXAMPLE} element={<UIExample />} />

          <Route path="*" element={<Index />} />

          {/* <Route path="/errors/InvalidSession" element={<InvalidSession />} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
};
export default App;
